import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import Divider from "@mui/material/Divider";
import IconButton from "@mui/material/IconButton";
import Chip from "@mui/material/Chip";
import Button from "@mui/material/Button";
import Avatar from "@mui/material/Avatar";
import React, { useState } from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import {
  chooseOwnerTypeColor,
  findOwner,
  findWho,
} from "../../utils/functions";
import { Stack } from "@mui/material";
import { bondingBadge, emoji } from "../../utils/ListItems";
import { ReactionViewer } from "./ReactionViewer";
import { Reaction } from "../atoms/Reaction";
import { profileReactions } from "../../utils/constants";
import { UploadPicButton } from "../../UI/UploadPicButton";
import { AddRelation } from "./AddRelation";
import MoreVertIcon from "@mui/icons-material/MoreVert";
import { StyledMenu, useStyles } from "../../utils/Theme";
import { useSelector } from "react-redux";
import { BondingBadgePopup } from "../atoms/BondingBadgePopup";

export const ProfileCard = ({
  src,
  name,
  ownerType,
  relation,
  desc,
  reaction = [],
  lg_id,
  url = "",
  fstr = "",
  lg_score = 0,
  engagement_score = 0,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [openReaction, setopenReaction] = useState(false);
  const [open, setOpen] = useState(false);
  const [showBadge, setShowBadge] = useState(false);
  const { userStatus } = useSelector((state) => state.config);
  const classes = useStyles();

  //-----------------------Declare Functions Here-----------------------------//

  const handleClick = (event) => {
    setOpen(event.currentTarget);
  };
  const handleClose = () => {
    setopenReaction(false);
  };
  const who = findWho(lg_id);
  const owner = findOwner(lg_id);
  const showButton =
    (owner?.owner_type === "Self" || owner?.owner_type === "Owner") &&
    userStatus === "active";
  return (
    <>
      <Box
        sx={{ minHeight: "180px", width: "100%", p: 1, position: "relative" }}
      >
        <Stack spacing={-1}>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ display: "flex", alignItems: "center", position: "relative" }}
          >
            <Stack spacing={0}>
              <Box
                sx={{
                  position: "relative",
                  mt: "-50px !important",
                }}
                className="derek"
              >
                <Box
                  sx={{
                    position: "relative",
                    backgroundColor: "#fff",
                    width: "fit-content",
                    borderTopLeftRadius: "50%",
                    borderTopRightRadius: "50%",
                    paddingLeft: "3px",
                    paddingTop: "3px",
                  }}
                >
                  <CustomAvatar
                    src={src}
                    sx={{ height: "120px", width: "120px" }}
                  />
                  {showButton && (
                    <Box sx={{ position: "absolute", bottom: 0, left: "75px" }}>
                      <UploadPicButton lg_id={lg_id} fstr={fstr} src={src} />
                    </Box>
                  )}
                </Box>
              </Box>
              <Stack
                direction={"row"}
                spacing={1}
                sx={{ position: "relative" }}
              >
                <Box sx={{ width: "100%" }}>
                  <Typography
                    sx={{
                      width: "100%",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textWrap: "wrap",
                      fontSize: "18px",
                      lineHeight: "25px",
                      color: "text.heading",
                    }}
                    align="center"
                  >
                    {name}
                  </Typography>
                  {relation ? (
                    <Typography
                      sx={{
                        width: "100%",
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                      }}
                      align="center"
                      variant="caption"
                      component={"div"}
                    >
                      {relation}
                      <Tooltip title={ownerType}>
                        <Badge
                          sx={{
                            ml: 1,
                            ".MuiBadge-dot": {
                              backgroundColor: chooseOwnerTypeColor(ownerType),
                              borderRadius: "0px",
                              height: "12px",
                              minWidth: "4px",
                            },
                          }}
                          variant="dot"
                        />
                      </Tooltip>
                    </Typography>
                  ) : (
                    userStatus === "active" && (
                      <AddRelation lg_id={lg_id} name={name} />
                    )
                  )}
                </Box>
                <Button
                  sx={{
                    minWidth: 0,
                    p: 0,
                    height: "24px",
                    position: "absolute",
                    right: "-30px",
                  }}
                  onClick={() => setShowBadge(true)}
                >
                  <img
                    src={bondingBadge["bonding"]}
                    style={{ height: "24px", width: "24px" }}
                  />
                </Button>
              </Stack>
            </Stack>

            <Stack
              spacing={1}
              sx={{
                position: "absolute",
                left: "120px",
                top: "4px",
                maxHeight: "57px",
                overflowY: "scroll",
              }}
              className={classes.profileBadge}
            >
              {reaction.length > 0 && (
                <Box
                  sx={{
                    display: "flex",
                    flexWrap: "wrap",
                    maxWidth: "250px",
                    mb: " 8px ! important",
                  }}
                >
                  {reaction.map((item, idx) => (
                    <Chip
                      key={idx}
                      sx={{
                        m: 0.2,
                        height: "unset",
                        fontSize: "10px",
                        "&div > .MuiChip-label": { pl: "8px", pr: "8px" },
                      }}
                      avatar={
                        <Avatar
                          sx={{
                            "&.MuiChip-avatar": {
                              height: "16px",
                              width: "16px",
                            },
                          }}
                          alt=""
                          src={emoji[item.reaction]}
                        />
                      }
                      label={item.reaction_cnt}
                      onClick={() => {
                        setopenReaction(true);
                      }}
                    />
                  ))}
                </Box>
              )}
            </Stack>
          </Stack>
          <Divider textAlign="right">
            {userStatus === "active" && (
              <Reaction
                sx={{ p: 0, minWidth: 0 }}
                label={false}
                reaction={profileReactions}
                profile={true}
                lg_id={lg_id}
                uniq_reaction_list={reaction}
                url={url}
                reactionSummary={reaction}
              />
            )}
          </Divider>
          <Typography
            align="center"
            variant="body1"
            sx={{
              textTransform: "capitalize",
              fontStyle: "italic",
            }}
          >
            {'" ' + desc + ' "'}
          </Typography>
        </Stack>
        {who?.relation && userStatus === "active" && (
          <Box sx={{ position: "absolute", top: 0, right: "0px" }}>
            <IconButton
              id="demo-customized-button"
              aria-controls={open ? "demo-customized-menu" : undefined}
              aria-haspopup="true"
              aria-expanded={open ? "true" : undefined}
              variant="contained"
              onClick={handleClick}
            >
              <MoreVertIcon />
            </IconButton>
            <StyledMenu
              anchorEl={open}
              open={Boolean(open)}
              onClose={() => setOpen(false)}
            >
              <AddRelation
                lg_id={lg_id}
                spouse={who?.relation !== null ? true : false}
                gender={
                  who?.gender === "Female"
                    ? "Wife"
                    : who?.gender === "Male"
                    ? "Husband"
                    : null
                }
                name={name}
              />
            </StyledMenu>
          </Box>
        )}
      </Box>
      {openReaction && (
        <ReactionViewer
          lg_id={lg_id}
          entry_id={1}
          entry_type={"profile"}
          open={openReaction}
          close={handleClose}
        />
      )}
      {showBadge && (
        <BondingBadgePopup
          open={showBadge}
          close={() => setShowBadge(false)}
          lg_score={lg_score}
          engagement_score={engagement_score}
        />
      )}
    </>
  );
};
