import { createSlice } from "@reduxjs/toolkit";
const initialState = 0;

export const uploadProgress = createSlice({
  name: "uploadProgress",
  initialState,
  reducers: {
    setUploadProgress: (state, action) => {
      return (state = action.payload);
    },
    resetUploadProgress: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setUploadProgress, resetUploadProgress } =
  uploadProgress.actions;

export default uploadProgress.reducer;
