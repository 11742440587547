import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  altMsg: "",
  error: false,
  message: "",
  severity: "",
  param: undefined,
};

export const alertSlice = createSlice({
  name: "alert",
  initialState,
  reducers: {
    setAlert: (state, action) => {
      state.altMsg = action.payload.altMsg;
      state.error = action.payload.error;
      state.message = action.payload.message;
      state.severity = action.payload.severity;
      state.param = action.payload?.param;
    },
    resetAlert: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setAlert, resetAlert } = alertSlice.actions;

export default alertSlice.reducer;
