import { setAlert } from "../redux/slice/alert";
import { updateAppReady } from "../redux/slice/configuration";
import {
  updateAllrel,
  updateAllrelrev,
  updateRelations,
  updateRelcode,
  updateRelrevcode,
} from "../redux/slice/userSummary";
import { store } from "../redux/store";
import { getApi } from "../utils/functions";

export const getRelationsApi = (lgId) => {
  return getApi("getRelations", `relation_type=3CousinAnd&lg_id=${lgId}`).then(
    (response) => {
      if (!response.error) {
        store.dispatch(updateRelations(response.output));
        store.dispatch(updateAllrel(response.allrel));
        store.dispatch(updateAllrelrev(response.allrelrev));
        store.dispatch(updateRelcode(response.relcode));
        store.dispatch(updateRelrevcode(response.relrevcode));
        store.dispatch(updateAppReady(true));
      } else store.dispatch(setAlert(response));
    }
  );
};
