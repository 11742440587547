import { EditorState, convertFromRaw, ContentState } from "draft-js";
import React, { useMemo, useState } from "react";
import Editor from "@draft-js-plugins/editor";
import createMentionPlugin from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import createLinkifyPlugin from "@draft-js-plugins/linkify";

export const RichTextViewer = ({
  text,
  truncate = false,
  truncateChar = 150,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const [editorState, setEditorState] = useState(() =>
    EditorState.createWithContent(convertFromRaw(text, "update-state"))
  );
  const { MentionSuggestions, plugins } = useMemo(() => {
    const mentionPlugin = createMentionPlugin();
    const linkifyPlugin = createLinkifyPlugin();
    const { MentionSuggestions } = mentionPlugin;
    const plugins = [mentionPlugin, linkifyPlugin];
    return { plugins, MentionSuggestions };
  }, []);

  //-----------------------Declare Functions Here-----------------------------//

  const handleTruncate = (editorState, charCount) => {
    const contentState = editorState.getCurrentContent();
    const blocks = contentState.getBlockMap();
    let count = 0;
    let isTruncated = false;
    const truncatedBlocks = [];
    blocks.forEach((block) => {
      if (!isTruncated) {
        const length = block.getLength();
        if (count + length > charCount) {
          isTruncated = true;
          const truncatedText = block.getText().slice(0, charCount - count);
          const state = ContentState.createFromText(`${truncatedText}...`);
          truncatedBlocks.push(state.getFirstBlock());
        } else {
          truncatedBlocks.push(block);
        }
        count += length + 1;
      }
    });
    if (isTruncated) {
      const state = ContentState.createFromBlockArray(truncatedBlocks);
      return EditorState.createWithContent(state);
    }
    return editorState;
  };

  return (
    <div id="rich-text-viewer">
      <Editor
        editorKey={"editor"}
        editorState={
          truncate
            ? handleTruncate(
                EditorState.push(
                  editorState,
                  convertFromRaw(text, "update-state")
                ),
                truncateChar
              )
            : EditorState.push(
                editorState,
                convertFromRaw(text, "update-state")
              )
        }
        onChange={setEditorState}
        plugins={plugins}
        readOnly={true}
      />
      <MentionSuggestions
        open={true}
        suggestions={[]}
        onOpenChange={() => {}}
      />
    </div>
  );
};
