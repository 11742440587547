import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React from "react";
import Skeleton from "@mui/material/Skeleton";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";

export const WisdomSkleton = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  //-----------------------Declare function Here-----------------------------//

  return (
    <Card
      sx={{
        width: "100%",
        p: 0.5,
      }}
    >
      <Stack spacing={0.75}>
        <Grid container>
          <Grid
            sx={{
              height: { xs: "fit-content", sm: "100%" },
              position: "relative",
              width: "100%",
            }}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%",
                width: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Skeleton variant="text" width={"80%"} height={38} />
                </Box>
              </Box>
              <Stack direction={"row"} spacing={1}>
                <Skeleton variant="text" width={70} height={38} />
                <Skeleton variant="text" width={70} height={38} />
                <Skeleton variant="text" width={70} height={38} />
              </Stack>{" "}
              <Box
                sx={{
                  //   display: "flex",
                  //   alignItems: "center",
                  height: "100%",
                  width: "100%",
                }}
              >
                <Skeleton variant="text" width={"100%"} />

                <Skeleton variant="text" width={"85%"} />
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <Skeleton variant="text" width={25} />
                </Box>
                <Box sx={{ justifyContent: "flex-end" }}>
                  <Skeleton variant="text" width={25} />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <>
          <Divider sx={{ width: "100%" }} />
          <Grid container sx={{ pb: 0.5 }}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                borderStyle: "solid",
                borderColor: "border.main",
                borderWidth: 0,
                borderRightWidth: "thin",
              }}
            >
              <Skeleton variant="text" width={"30%"} />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Skeleton variant="text" width={"30%"} />
            </Grid>
          </Grid>
        </>
      </Stack>
    </Card>
  );
};
