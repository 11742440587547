import Editor from "@draft-js-plugins/editor";
import { EditorState, convertToRaw, RichUtils, convertFromRaw } from "draft-js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import createEmojiPlugin from "@draft-js-plugins/emoji";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createToolbarPlugin, {
  Separator,
} from "@draft-js-plugins/static-toolbar";
import {
  ItalicButton,
  BoldButton,
  UnderlineButton,
} from "@draft-js-plugins/buttons";
import createMentionPlugin from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/static-toolbar/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import { useGetMentionQuery } from "../redux/slice/mentions";

const DraftField = React.forwardRef(function DraftField(props, ref) {
  const {
    component: Component,
    editorRef,
    handleOnChange,
    handleKeyCommand,
    plugins,
    editorState,
    handleBeforeInput,
    ...rest
  } = props;

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      editorRef?.current?.focus();
    },
  }));

  return (
    <Component
      {...rest}
      editorState={editorState}
      ref={editorRef}
      onChange={handleOnChange}
      handleKeyCommand={handleKeyCommand}
      plugins={plugins}
      handleBeforeInput={handleBeforeInput}
    />
  );
});

export const CustomTextArea = ({
  value = "",
  onChange = () => {},
  maxLength = 250,
  errors = false,
  label = "",
  required = false,
  lg_id = "",
}) => {
  const { data } = useGetMentionQuery(lg_id);
  const [suggestions, setSuggestions] = useState([]);
  useEffect(() => {
    if (data) setSuggestions(data);
  }, [data]);

  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const editorRef = React.useRef(null);
  useEffect(() => {
    if (value !== "")
      setEditorState(
        EditorState.push(editorState, convertFromRaw(value, "update-state"))
      );
  }, []);

  const handleOnChange = (newEditorState) => {
    setEditorState(newEditorState);
    onChange(convertToRaw(newEditorState.getCurrentContent()));
  };
  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();
    let length = 0;
    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;
        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }
          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };
  const handleBeforeInput = () => {
    if (maxLength) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText("").length;
      const selectedTextLength = getLengthOfSelectedText();
      if (currentContentLength - selectedTextLength > maxLength - 1) {
        return "handled";
      }
    }
  };
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChangeValue(newState);
      return "handled";
    }
    return "not-handled";
  };
  const onChangeValue = (value) => {
    setEditorState(value);
  };
  const {
    MentionSuggestions,
    plugins,
    EmojiSuggestions,
    EmojiSelect,
    Toolbar,
  } = useMemo(() => {
    const toolbarPlugin = createToolbarPlugin();
    const emojiPlugin = createEmojiPlugin();
    const linkifyPlugin = createLinkifyPlugin();
    const mentionPlugin = createMentionPlugin();
    // eslint-disable-next-line no-shadow
    const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
    const { MentionSuggestions } = mentionPlugin;
    const { Toolbar } = toolbarPlugin;
    // eslint-disable-next-line no-shadow
    const plugins = [mentionPlugin, emojiPlugin, linkifyPlugin, toolbarPlugin];
    return {
      plugins,
      MentionSuggestions,
      EmojiSuggestions,
      EmojiSelect,
      Toolbar,
    };
  }, []);

  const [open, setOpen] = useState(false);
  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(async () => {
    if (data) setSuggestions(data);
  }, []);

  return (
    <Box className={errors ? "RichTextEditorerror" : ""}>
      <EmojiSuggestions />
      <TextField
        fullWidth
        multiline
        minRows={3}
        maxRows={5}
        label={label}
        value="dummy"
        required={required}
        onChange={() => {}}
        error={errors ? true : false}
        helperText={errors ? errors?.message : null}
        inputProps={{
          maxLength: maxLength ? maxLength : null,
        }}
        InputProps={{
          inputProps: {
            component: Editor,
            editorRef,
            editorState,
            handleOnChange,
            handleKeyCommand,
            plugins,
            handleBeforeInput,
          },
          inputComponent: DraftField,
        }}
      />
      <Box sx={{ position: "relative" }}>
        <Toolbar>
          {(externalProps) => (
            <>
              <BoldButton {...externalProps} />
              <ItalicButton {...externalProps} />
              <UnderlineButton {...externalProps} />
              <Separator {...externalProps} />
              <EmojiSelect {...externalProps} />
            </>
          )}
        </Toolbar>
      </Box>
      <Box data-toggle="popover">
        <MentionSuggestions
          open={open}
          onOpenChange={onOpenChange}
          suggestions={suggestions}
          onSearchChange={onSearchChange}
          onAddMention={() => {}}
        />
      </Box>
    </Box>
  );
};
