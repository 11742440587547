import React from "react";
import { Reaction } from "../atoms/Reaction";
import Grid from "@mui/material/Grid";
import Button from "@mui/material/Button";
import Stack from "@mui/material/Stack";
import { t } from "i18next";
import { findSelf, isBlocked } from "../../utils/functions";
import CustomAvatar from "../../UI/CustomAvathar";

export const ReactionAndCommentBtn = ({
  id,
  entry_type,
  lg_id,
  my_reaction,
  reaction_cnt,
  uniq_reaction_list,
  reaction,
  onClick,
}) => {
  const self = findSelf();

  return (
    <>
      {!isBlocked(lg_id) ? (
        <>
          <Grid container sx={{ pb: 0.5 }}>
            <Grid
              item
              xs={10}
              sx={{
                display: "flex",
                justifyContent: "center",
                p: 0.5,
                maxHeight: "40px",
              }}
            >
              <Stack
                spacing={1}
                direction={"row"}
                sx={{ display: "flex", alignItems: "center", width: "100%" }}
              >
                <CustomAvatar
                  src={self?.readURL}
                  sx={{ height: "36px", width: "36px" }}
                />
                <Button
                  sx={{
                    backgroundColor: "white.main",
                    color: "grey.text",
                    display: "flex",
                    width: "100%",
                    justifyContent: "flex-start",
                    textTransform: "none",
                    fontSize: "14px",
                    borderStyle: "solid",
                    borderColor: "border.main",
                    borderWidth: "thin",
                    borderRightWidth: "thin",
                    cursor: "text",
                    height: "36px",
                  }}
                  onClick={() => onClick("comment")}
                >
                  {t("display.text.button.comment")}
                </Button>
              </Stack>
            </Grid>
            <Grid
              item
              xs={2}
              sx={{
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Reaction
                id={id}
                entry_type={entry_type}
                lg_id={lg_id}
                my_reaction={my_reaction}
                reaction_cnt={reaction_cnt}
                uniq_reaction_list={uniq_reaction_list}
                reaction={reaction}
                label={false}
              />
            </Grid>
          </Grid>
        </>
      ) : null}
    </>
  );
};
