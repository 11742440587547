import { resetAuthentication } from "../redux/slice/authentication";
import { store } from "../redux/store";
import { postApi } from "../utils/functions";
import { getUserSummaryApi } from "./getUserSummaryApi";

export const activateLifografApi = (formData) => {
  postApi("activateUser", formData).then((response) => {
    if (!response.error) {
      if (formData.accept_lg === "yes") getUserSummaryApi();
      else {
        store.dispatch(resetAuthentication());
        setTimeout(() => window.location.reload(), 100);
      }
    }
  });
};
