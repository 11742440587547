import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  relReqNotification: "0",
  nudgePost: {
    nudgeAddRelations: 0,
    nudgeNewPost: 0,
    nudgeProfileDetails: 0,
  },
};

export const notificationSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {
    updateRelReqNotification: (state, action) => {
      state.relReqNotification = action.payload;
    },
    updateNudgePost: (state, action) => {
      state.nudgePost = { ...state.nudgePost, ...action.payload };
    },
    resetNotification: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { updateRelReqNotification, updateNudgePost, resetNotification } =
  notificationSlice.actions;

export default notificationSlice.reducer;
