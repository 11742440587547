import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import Tooltip from "@mui/material/Tooltip";
import { t } from "i18next";
import { ownerIcons } from "../../utils/ListItems";

export const UserNameCard = ({ src, name, relation, ownerType }) => {
  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        width: "100%",
      }}
    >
      <Box>
        <CustomAvatar src={src} />
      </Box>
      <Box sx={{ width: "70%" }}>
        <Typography
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textWrap: "nowrap",
            alignItems: "flex-start",
          }}
          align="left"
        >
          {name}
        </Typography>
        {relation && (
          <Typography
            sx={{
              width: "100%",
              overflow: "hidden",
              textOverflow: "ellipsis",
              textWrap: "nowrap",
            }}
            align="left"
            variant="caption"
            component={"div"}
          >
            {relation}
            <Tooltip
              title={ownerType ? t(`display.text.toolTip.${ownerType}`) : null}
            >
              {(ownerType === "Collab" || ownerType === "Owner") && (
                <img
                  style={{ marginLeft: "4px", height: "12px", width: "12px" }}
                  src={ownerIcons[ownerType]}
                />
              )}
            </Tooltip>
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
