import React from "react";
import { CustomDialogSwipeUp } from "../../UI/CustomDialogSwipeUp";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import { useDispatch, useSelector } from "react-redux";
import { updateCurrentPage, updateMode } from "../../redux/slice/createLg";
import AccountCircleIcon from "@mui/icons-material/AccountCircle";
import { t } from "i18next";
import { InvitePeople } from "./InvitePeople";

export const CreateLgMainPage = ({ open, close }) => {
  const bodystyles = { fontSize: "16px", fontWeight: 400, color: "#666666" };
  const dispatch = useDispatch();
  const { userStatus } = useSelector((state) => state.config);

  return (
    <>
      <CustomDialogSwipeUp
        open={open}
        close={close}
        title={t("display.text.text.addAFamilyMember")}
      >
        <Stack spacing={2}>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
          >
            {t("display.text.text.createLgH1")}
          </Typography>
          <ul>
            <li>
              <Typography sx={bodystyles}>
                {t("display.text.text.createLgP1")}
              </Typography>
            </li>
            <li>
              <Typography sx={bodystyles}>
                {t("display.text.text.createLgP2")}
              </Typography>
            </li>
            <li>
              <Typography sx={bodystyles}>
                {t("display.text.text.createLgP3")}
              </Typography>
            </li>
          </ul>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Button
              variant="contained"
              startIcon={<AccountCircleIcon />}
              onClick={() => {
                dispatch(updateMode("new"));
                dispatch(updateCurrentPage("start"));
              }}
              disabled={userStatus === "inactive"}
            >
              <Typography>
                {t("display.text.text.createNewLifograf")}
              </Typography>
            </Button>
          </Box>
          <Divider></Divider>
          <Typography
            sx={{ fontSize: "16px", fontWeight: 600, color: "#333333" }}
          >
            {t("display.text.text.createLgH2")}
          </Typography>
          <ul>
            <li>
              <Typography sx={bodystyles}>
                {t("display.text.text.createLgP4")}
              </Typography>
            </li>
            <li>
              <Typography sx={bodystyles}>
                {t("display.text.text.createLgP5")}
              </Typography>
            </li>
          </ul>
          <InvitePeople />
        </Stack>
      </CustomDialogSwipeUp>
    </>
  );
};
