import React, { useEffect } from "react";
import { ProfileTabs } from "../molecules/ProfileTabs";
import { useGetLgAboutQuery } from "../../redux/slice/lgabout";
import {
  updateActiveLgId,
  updateActiveLgName,
} from "../../redux/slice/profile";
import { useDispatch } from "react-redux";
import { findSelf } from "../../utils/functions";
import { ProfileHeader } from "../molecules/ProfileHeader";
import Stack from "@mui/material/Stack";
export const ProfileContainer = ({ profile }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const dispatch = useDispatch();
  const { data, isLoading, isFetching } = useGetLgAboutQuery(profile);
  useEffect(() => {
    if (data) {
      dispatch(updateActiveLgId(data?.output?.lg_id));
      dispatch(
        updateActiveLgName(
          `${data?.output?.first_name} ${data?.output?.last_name}`
        )
      );
    }
    return () => {
      dispatch(updateActiveLgId(findSelf()?.lg_id));
      dispatch(updateActiveLgName(""));
    };
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    document.getElementById("prof")?.scrollIntoView({ behavior: "smooth" });
  }, [isFetching, isLoading, data]);
  return (
    <Stack spacing={1}>
      <>
        <div id="prof"></div>
        <ProfileHeader
          loading={isLoading || isFetching}
          lgId={data?.output?.lg_id}
          data={data}
        />
        <ProfileTabs
          loading={isLoading || isFetching}
          lgId={data?.output?.lg_id}
          data={data}
        />
      </>
    </Stack>
  );
};
