import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  activeLgId: "",
  name: "",
};
export const profile = createSlice({
  name: "profile",
  initialState,
  reducers: {
    updateActiveLgId: (state, action) => {
      state.activeLgId = action.payload;
    },
    updateActiveLgName: (state, action) => {
      state.name = action.payload;
    },
    resetProfile: () => {
      return initialState;
    },
  },
});

export const { updateActiveLgId, resetProfile, updateActiveLgName } =
  profile.actions;
export default profile.reducer;
