import React from "react";
import defaultCoverPic from "../../assets/Img/cover.jpeg";
import { Img } from "../../UI/Img";
import Box from "@mui/material/Box";
import { UploadPicButton } from "../../UI/UploadPicButton";
import { findOwner, getCoverPicReadURL } from "../../utils/functions";
import { useSelector } from "react-redux";

export const ProfileCover = ({
  lg_id = "",
  fstr = "",
  type = "NOFILE",
  coverPicKey,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const owner = findOwner(lg_id);
  const { userStatus } = useSelector((state) => state.config);
  const showButton =
    (owner?.owner_type === "Self" || owner?.owner_type === "Owner") &&
    userStatus === "active";
  return (
    <Box key={coverPicKey} sx={{ position: "relative" }}>
      <Img
        style={{ height: "100%", width: "100%", cursor: "pointer" }}
        src={
          type === "NOFILE"
            ? defaultCoverPic
            : `${getCoverPicReadURL(lg_id)}?${coverPicKey}`
        }
      />
      {showButton && (
        <Box sx={{ position: "absolute", bottom: 1, right: "5px" }}>
          <UploadPicButton type="cover" lg_id={lg_id} fstr={fstr} src={type} />
        </Box>
      )}
    </Box>
  );
};
