import React, { useState } from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Card from "@mui/material/Card";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import MenuItem from "@mui/material/MenuItem";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { t } from "i18next";
import { CustomTextArea } from "../../UI/CustomTextArea";
import {
  findEventType,
  findWho,
  getChipValue,
  validatePlan,
  validateRichText,
} from "../../utils/functions";
import Typography from "@mui/material/Typography";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import LoadingButton from "@mui/lab/LoadingButton";
import {
  useEditWisdomMutation,
  usePostWisdomMutation,
} from "../../redux/slice/wisdom";
import { visibilityTypes, wisdomType } from "../../utils/ListItems";
import { useStyles } from "../../utils/Theme";
import { HelperIcon } from "../atoms/HelperIcon";
import { VisiblityHelperText } from "../atoms/VisiblityHelperText";
import { UpgradePromt } from "../../UI/UpgradePromt";
import CustomAvatar from "../../UI/CustomAvathar";

export const NewWisdom = ({
  open,
  close,
  mode = "new",
  comment_cnt,
  created_lg,
  created_lg_url,
  desc,
  link,
  dt,
  entry_created_by,
  file_type,
  id,
  type,
  my_reaction,
  reaction_cnt,
  readURL,
  title,
  uniq_reaction_list,
  updated_date,
  visibility,
  searchValue = "",
  user_connect_to_lg,
  lg_id,
  fstr,
  typ = "wisdom",
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [postWisdom, { status: postStatus }] = usePostWisdomMutation();
  const [editWisdom, { status: editStatus }] = useEditWisdomMutation();
  const [plan, setPlan] = useState(mode === "new" ? validatePlan() : "active");
  const classes = useStyles();
  const who = findWho(lg_id);
  const defaultValues = {
    title: mode === "edit" ? title : "",
    desc: mode === "edit" ? desc : "",
    type: mode === "edit" ? getChipValue(type, wisdomType) : [],
    visibility: mode === "edit" ? visibility : "dist",
  };
  const { handleSubmit, control, formState } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //-----------------------Declare Functions Here-----------------------------//

  const formatData = (data) => {
    let plan = validatePlan(typ, "none");
    if (plan === "upgrade") {
      setPlan(plan);
    } else {
      if (mode === "new") {
        postWisdom({
          ...data,
          lg_id: lg_id,
          type: data.type.map((item) => item.value).join("|"),
          typ: typ,
        }).then(() => close(false));
      } else if (mode === "edit") {
        editWisdom({
          ...data,
          lg_id: lg_id,
          type: data.type.map((item) => item.value).join("|"),
          typ: typ,
          fstr: fstr,
          id: id,
        }).then(() => close(false));
      }
    }
  };
  let enablebutton =
    mode === "new"
      ? !formState.isValid
      : !(formState.isDirty && formState.isValid);

  // do not delete the below variable
  let dummy = {
    disable: enablebutton,
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };

  return (
    <>
      {plan === "active" && (
        <CustomDialog
          open={open}
          close={close}
          title={
            <Stack
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={1}
              direction={"row"}
            >
              <CustomAvatar src={who?.readURL} />
              <Box>
                <Typography
                  variant="h6"
                  component={"div"}
                  align="center"
                  sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
                >
                  {who?.name}
                </Typography>
                <Typography
                  variant="caption"
                  component={"div"}
                  sx={{ fontSize: "14px" }}
                >
                  {t(`display.text.button.${findEventType(typ).title}`, {
                    count: 1,
                  })}
                </Typography>
              </Box>
            </Stack>
          }
          action={
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <LoadingButton
                variant="contained"
                disabled={enablebutton}
                onClick={handleSubmit((data) => formatData(data))}
                loading={postStatus === "pending" || editStatus === "pending"}
              >
                <Typography>
                  {t(
                    `display.text.button.${mode === "new" ? "create" : "edit"}`
                  )}
                </Typography>
              </LoadingButton>
            </Box>
          }
        >
          <Card sx={{ width: "100%", p: 1 }}>
            <Stack spacing={2}>
              <Controller
                name={"type"}
                control={control}
                render={({ field, fieldState }) => (
                  <Autocomplete
                    clearOnBlur
                    {...field}
                    multiple
                    options={wisdomType}
                    freeSolo={false}
                    onChange={(_, data) => field.onChange(data)}
                    renderTags={(value, getTagProps) =>
                      value.map((option, index) => (
                        <Chip
                          variant="outlined"
                          label={option.label}
                          {...getTagProps({ index })}
                        />
                      ))
                    }
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        label={t("display.text.inputField.wisdomType")}
                        required={true}
                        error={fieldState.error ? true : false}
                        helperText={
                          fieldState.error ? fieldState.error.message : null
                        }
                        // placeholder="Favorites"
                      />
                    )}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("display.text.error.fieldIsRequired", {
                      param1: t("display.text.inputField.wisdomType"),
                    }),
                  },
                  validate: {
                    lengthOfEachChip: (value) => {
                      return (
                        value.every((item) => item.label.length <= 50) ||
                        `${t("display.text.error.noMoreChar")
                          .split(" ")
                          .slice(0, 6)
                          .join(" ")} 50 ${
                          t("display.text.error.noMoreChar").split(" ")[6]
                        }`
                      );
                    },
                    chipArrayCount: (value) =>
                      value.length <= 5 ||
                      `${
                        t("display.text.error.maxChipEntryError").split(" ")[0]
                      } 5 ${t("display.text.error.maxChipEntryError").slice(
                        5
                      )}`,
                    checkforspecialCharacters: (value) =>
                      value.every(
                        (item) => item.label.includes("|") === false
                      ) || t("display.text.error.pipeOperatorError"),
                  },
                }}
              />
              <Controller
                name={"title"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    label={t("display.text.inputField.title")}
                    type={"text"}
                    inputProps={{
                      maxLength: 46,
                    }}
                    required={true}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                    sx={{ width: "100%" }}
                  />
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("display.text.error.fieldIsRequired", {
                      param1: t("display.text.inputField.title"),
                    }),
                  },
                  maxLength: {
                    value: 45,
                    message: t("display.text.error.maxCharacters", {
                      param1: 45,
                    }),
                  },
                }}
              />
              <Controller
                name={"desc"}
                control={control}
                render={({ field, fieldState }) => (
                  <CustomTextArea
                    onChange={field.onChange}
                    error={fieldState.error}
                    label={t("display.text.inputField.description")}
                    value={field.value}
                    required={true}
                    maxLength={251}
                    lg_id={lg_id}
                  />
                )}
                rules={{
                  validate: {
                    length: (value) =>
                      value.blocks[0].text.length <= 250 ||
                      t("display.text.error.maxCharacters", {
                        param1: 250,
                      }),
                    required: (value) =>
                      validateRichText(
                        value.blocks[0].text,
                        formState.isDirty
                      ) === true ||
                      t("display.text.error.fieldIsRequired", {
                        param1: t("display.text.inputField.description"),
                      }),
                  },
                }}
              />
              <Controller
                name={"visibility"}
                control={control}
                render={({ field, fieldState }) => (
                  <TextField
                    {...field}
                    classes={{
                      root: classes.textFieldRoot, // apply class here
                    }}
                    select={true}
                    label={t("display.text.inputField.Visiblity")}
                    value={field.value}
                    required={true}
                    InputProps={{
                      endAdornment: (
                        <HelperIcon helperText={<VisiblityHelperText />} />
                      ),
                    }}
                    error={fieldState.error ? true : false}
                    helperText={
                      fieldState.error ? fieldState.error.message : null
                    }
                  >
                    {visibilityTypes.map((option, index) => (
                      <MenuItem key={index} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
                rules={{
                  required: {
                    value: true,
                    message: t("display.text.error.fieldIsRequired", {
                      param1: t("display.text.inputField.Visiblity"),
                    }),
                  },
                }}
              />
            </Stack>
          </Card>
        </CustomDialog>
      )}
      {plan === "upgrade" && (
        <>
          <UpgradePromt
            open={plan === "upgrade"}
            close={() => {
              close(false);
            }}
          />
        </>
      )}
    </>
  );
};
