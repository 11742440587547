import useScrollTrigger from "@mui/material/useScrollTrigger";
import Slide from "@mui/material/Slide";
import React from "react";

export function HideOnScroll({ children, window, hide = true }) {
  //-----------------------Declare Hooks Here-----------------------------//

  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
  });

  return (
    <>
      {hide ? (
        <Slide appear={false} direction="down" in={!trigger}>
          {children}
        </Slide>
      ) : (
        <>{children}</>
      )}
    </>
  );
}
