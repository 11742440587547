import Stack from "@mui/material/Stack";
import { MomentsSkleton } from "./MomentsSkleton";
import { LifeEventSkleton } from "./LifeEventSkleton";
import { WisdomSkleton } from "./WisdomSkleton";

export const HomeFeedSkleton = () => {
  return (
    <Stack spacing={1}>
      <MomentsSkleton />
      <WisdomSkleton />
      <LifeEventSkleton />
    </Stack>
  );
};
