import Box from "@mui/material/Box";
import Tooltip from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import IconButton from "@mui/material/IconButton";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import Fade from "@mui/material/Fade";

export const HelperIcon = ({ helperText }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [tooltipIsOpen, setTooltipIsOpen] = useState(false);

  //-----------------------Declare Functions Here-----------------------------//

  const onClose = () => {
    setTooltipIsOpen(false);
  };

  return (
    <Box className="helperIcon">
      <Tooltip
        open={tooltipIsOpen}
        onOpen={() => setTooltipIsOpen(true)}
        onClose={onClose}
        TransitionComponent={Fade}
        TransitionProps={{ timeout: 1000 }}
        title={<Typography variant="body1">{helperText}</Typography>}
      >
        <IconButton
          color="primary"
          aria-label="help icon"
          component="span"
          tabIndex={-1}
          sx={{ p: 0 }}
          onClick={() => setTooltipIsOpen(!tooltipIsOpen)}
        >
          <InfoOutlinedIcon sx={{ color: "secondary" }} />
        </IconButton>
      </Tooltip>
    </Box>
  );
};
