import React from "react";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import CreateNewLifografButton from "./CreateNewLifografButton";
import { useStyles } from "../../utils/Theme";
import { Relations } from "./Relations";
import { LeftPaneProfileCard } from "../atoms/LeftPaneProfileCard";

export const LeftNavigation = ({ toggle }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const classes = useStyles();
  return (
    <Box sx={{ height: "100vh" }}>
      <Box onClick={toggle}>
        <LeftPaneProfileCard />
      </Box>
      <Stack sx={{ p: 1 }} spacing={1}>
        <Stack
          direction={"row"}
          sx={{ display: "flex", justifyContent: "space-evenly" }}
        >
          <Box onClick={toggle}>
            <CreateNewLifografButton />
          </Box>
          {/* <Box onClick={toggle}> *Need to research and fix this item*/}
        </Stack>
        {/* </Box> */}
        <Card className={classes.root} sx={{ overflowY: "scroll" }}>
          <Relations toggle={toggle} />
        </Card>
      </Stack>
    </Box>
  );
};
