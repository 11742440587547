import React, { useState } from "react";
import Stack from "@mui/material/Stack";
import Button from "@mui/material/Button";
import Snackbar from "@mui/material/Snackbar";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import { useLocation } from "react-router-dom";
import { SocialIcon } from "react-social-icons/component";
import "react-social-icons/whatsapp";
import "react-social-icons/facebook";
import { findSelf } from "../../utils/functions";
import { useSelector } from "react-redux";
import { t } from "i18next";

export const InvitePeople = ({ close = () => {} }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const location = useLocation();
  const { inviteToken } = useSelector((state) => state.config);
  const [open, setOpen] = useState(false);
  let link = `${window.location.hostname}/${
    location.pathname === "/" ? "signup" : "login"
  }?ref=${findSelf()?.lg_url}&token=${encodeURIComponent(inviteToken)}`;
  let encodedlink = encodeURIComponent(link);
  const vertical = "bottom";
  const horizontal = "center";
  return (
    <>
      <Stack
        sx={{ width: "100%", display: "flex", justifyContent: "center" }}
        direction={"row"}
        spacing={5}
      >
        <SocialIcon
          network="whatsapp"
          url={`https://wa.me/?text=${encodedlink}`}
          target="_blank"
          onClick={close}
        />
        <SocialIcon
          network="facebook"
          url={`https://www.facebook.com/sharer/sharer.php?u=${encodedlink};src=sdkpreparse`}
          target="_blank"
          onClick={close}
        />
        <Button
          sx={{ p: 0, color: "grey" }}
          onClick={() => {
            navigator.clipboard.writeText(link);
            setOpen(true);
            // close();
          }}
        >
          <ContentCopyIcon sx={{ fontSize: "36px" }} />
        </Button>
        <Snackbar
          sx={{
            "& .MuiSnackbarContent-root": {
              minWidth: "0px",
            },
          }}
          anchorOrigin={{ vertical, horizontal }}
          open={open}
          onClose={() => setOpen(false)}
          message={t("display.text.text.linkCopied")}
          key={vertical + horizontal}
          autoHideDuration={600}
        />
      </Stack>
    </>
  );
};
