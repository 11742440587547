import React from "react";
import Grid from "@mui/material/Grid";
import Box from "@mui/system/Box";
import SignUp from "../organism/Signup";
import Container from "@mui/system/Container";
import { Footer } from "../atoms/Footer";
import { assetSource } from "../../utils/ListItems";

const Signup = () => {
  return (
    <Container sx={{ p: 0 }} fixed>
      <Grid
        container
        sx={{
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          height: "100vh",
        }}
      >
        <Grid
          item
          xs={12}
          sm={12}
          md={7}
          lg={6}
          sx={{
            padding: {
              xs: 1,
              sm: 1,
              md: 2,
              lg: 3,
            },
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            width: "100%",
          }}
        >
          <SignUp />
        </Grid>

        <Grid
          item
          sm={12}
          md={5}
          lg={6}
          sx={{
            display: { xs: "none", sm: "view" },
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <Box
            sx={{
              display: { sm: "none", md: "view" },
            }}
          >
            <img
              style={{ width: "100%" }}
              src={`${assetSource}/img_family`}
              alt={"family"}
            />
          </Box>
        </Grid>
        <Footer />
      </Grid>
    </Container>
  );
};
export default Signup;
