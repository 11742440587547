import React from "react";
import Box from "@mui/material/Box";
import { useSelector } from "react-redux";
import { OwnerCard } from "../atoms/OwnerCard";
import { findSelf } from "../../utils/functions";
import { Trending } from "../atoms/Trending";
import Stack from "@mui/material/Stack";
import { LogoutButton } from "../atoms/LogoutButton";
import { SettingsButton } from "../atoms/SettingsButton";
import { SubscriptionButton } from "../atoms/SubscriptionButton";

export const RightNavigation = ({ toggle }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { device, userStatus } = useSelector((state) => state.config);
  const self = findSelf();

  return (
    <Stack spacing={2} sx={{ p: 1, width: "100%" }}>
      <OwnerCard
        readURL={self?.readURL}
        name={`${self?.fname} ${self?.lname}`}
        status={userStatus}
      />
      {(device === "sm" || device === "md") && (
        <Box>
          <Trending />
        </Box>
      )}
      <Box onClick={toggle}>
        <SubscriptionButton />
      </Box>
      <Box onClick={toggle}>
        <SettingsButton />
      </Box>
      <Box onClick={toggle}>
        <LogoutButton />
      </Box>
    </Stack>
  );
};
