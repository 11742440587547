// import { fetchFCMTokenOnLogin } from "../firebase";
import { setAlert } from "../redux/slice/alert";
import {
  updateIsLoggedIn,
  updateUserEmail,
  updateUserId,
  updateUserName,
  updateUserToken,
} from "../redux/slice/authentication";
import { store } from "../redux/store";
import { baseURL } from "../utils/constants";

export const googleAuthenticationApi = (token, referrer) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify({
      authToken: token,
      referrer: referrer,
    }),
  };
  try {
    let api = fetch(`${baseURL}/gAuth`, requestOptions)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (!responseJSON.error) {
          store.dispatch(updateUserToken(responseJSON.usrToken));
          store.dispatch(updateUserId(responseJSON.usrId));
          store.dispatch(updateUserEmail(responseJSON.usrEmail));
          store.dispatch(updateUserName(responseJSON.usrName));
          store.dispatch(updateIsLoggedIn(true));
          // fetchFCMTokenOnLogin();
          return true;
        }
        store.dispatch(setAlert({ ...responseJSON }));
        return false;
      });
    return api;
  } catch (e) {
    console.error(e);
    return false; // { error: true, message: helperText.errors.apiFailed };
  }
};
