import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";

export const LgAboutSkleton = () => {
  return (
    <Box sx={{ mt: 1 }}>
      <Stack spacing={1}>
        {Array(6)
          .fill()
          .map((_, idx) => (
            <Card key={idx} sx={{ width: "100%", p: 1 }}>
              <Stack spacing={1}>
                <Skeleton sx={{ fontSize: "1.5rem" }} width={"30%"} />
                <Divider></Divider>
                <Skeleton sx={{ fontSize: "0.75rem" }} width={"70%"}></Skeleton>
                <Skeleton sx={{ fontSize: "0.75rem" }} width={"40%"}></Skeleton>
              </Stack>
            </Card>
          ))}
      </Stack>
    </Box>
  );
};
