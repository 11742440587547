import React from "react";
import { ProfileContainer } from "../organism/ProfileContainer";
import { useParams } from "react-router-dom";
export const Profile = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const urlParams = useParams();

  return <ProfileContainer profile={urlParams.id} />;
};
