import { setAlert } from "../redux/slice/alert";
import { store } from "../redux/store";
import { baseURL, somethingWentWrong } from "../utils/constants";

export const resetPasswordApi = async (FormData) => {
  const requestOptions = {
    method: "POST",
    headers: { "Content-Type": "application/json" },
    body: JSON.stringify(FormData),
  };

  try {
    let api = fetch(`${baseURL}/resetPwd`, requestOptions)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (!responseJSON.error) {
          store.dispatch(setAlert({ ...responseJSON, error: true }));
          return responseJSON;
        } else {
          store.dispatch(setAlert({ ...responseJSON }));
          return responseJSON;
        }
      })
      .catch((e) => {
        store.dispatch(setAlert(somethingWentWrong));
      });
    return api;
  } catch (e) {
    store.dispatch(setAlert(somethingWentWrong));
    console.error(e);
    return false; // { error: true, message: helperText.errors.apiFailed };
  }
};
