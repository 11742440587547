import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import TextField from "@mui/material/TextField";
import { t } from "i18next";
import React from "react";
import { useForm, Controller } from "react-hook-form";
import { regularExpressions } from "../../utils/constants";
import LoadingButton from "@mui/lab/LoadingButton";
import { useChangePasswordMutation } from "../../redux/slice/lifografSettings";
import { useNavigate } from "react-router-dom";

export const ChangePasswordPage = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [changePassword, { isLoading }] = useChangePasswordMutation();
  const navigate = useNavigate();
  let defaultValues = { current_pwd: "", new_pwd: "", confirm_pwd: "" };
  const { handleSubmit, control, formState, getValues, reset } = useForm({
    mode: "onChange",
    defaultValues,
  });
  //-----------------------Declare Functions Here-----------------------------//

  const formatData = (data) => {
    changePassword(data).then(() => {
      reset({ current_pwd: "", new_pwd: "", confirm_pwd: "" });
      navigate("/settings");
    });
  };
  return (
    <>
      <Card sx={{ p: 1 }}>
        <Stack spacing={2}>
          <Controller
            name={"current_pwd"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("display.text.inputField.currentPassword")}
                type={"password"}
                required={true}
                error={fieldState.error ? true : false}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={{ width: "100%" }}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t("display.text.error.fieldIsRequired"),
              },
            }}
          />
          <Controller
            name={"new_pwd"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("display.text.inputField.newPassword")}
                type={"password"}
                required={true}
                error={fieldState.error ? true : false}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={{ width: "100%" }}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t("display.text.error.fieldIsRequired"),
              },
              pattern: {
                value: regularExpressions.password,
                message: t("display.text.error.passwordValidationErr"),
              },
            }}
          />
          <Controller
            name={"confirm_pwd"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("display.text.inputField.confirmPassword")}
                type={"password"}
                required={true}
                error={fieldState.error ? true : false}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={{ width: "100%" }}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t("display.text.error.fieldIsRequired"),
              },
              validate: {
                positive: (value) =>
                  getValues("new_pwd") === value ||
                  t("display.text.error.passwordMissMatchErr"),
              },
            }}
          />
          <LoadingButton
            variant="contained"
            loading={isLoading}
            disabled={!formState.isValid}
            onClick={handleSubmit((data) => formatData(data))}
          >
            {t("display.text.button.save")}
          </LoadingButton>
        </Stack>
      </Card>
    </>
  );
};
