import Editor from "@draft-js-plugins/editor";
import { EditorState, convertToRaw, RichUtils, convertFromRaw } from "draft-js";
import React, { useCallback, useEffect, useMemo, useState } from "react";
import createEmojiPlugin from "@draft-js-plugins/emoji";
import createLinkifyPlugin from "@draft-js-plugins/linkify";
import createMentionPlugin from "@draft-js-plugins/mention";
import "@draft-js-plugins/mention/lib/plugin.css";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import "@draft-js-plugins/emoji/lib/plugin.css";
import "@draft-js-plugins/linkify/lib/plugin.css";
import { findSelf } from "../../utils/functions";
import { useGetMentionQuery } from "../../redux/slice/mentions";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import SendIcon from "@mui/icons-material/Send";
import { Controller, useForm } from "react-hook-form";
import { t } from "i18next";
import { usePostCommentMutation } from "../../redux/slice/comment";

const DraftField = React.forwardRef(function DraftField(props, ref) {
  const {
    component: Component,
    editorRef,
    handleOnChange,
    handleKeyCommand,
    plugins,
    editorState,
    handleBeforeInput,
    ...rest
  } = props;

  React.useImperativeHandle(ref, () => ({
    focus: () => {
      editorRef?.current?.focus();
    },
  }));

  return (
    <Component
      {...rest}
      editorState={editorState}
      ref={editorRef}
      onChange={handleOnChange}
      handleKeyCommand={handleKeyCommand}
      plugins={plugins}
      handleBeforeInput={handleBeforeInput}
    />
  );
});

const CommentBox = ({
  value = "",
  onChange,
  maxLength = 250,
  errors,
  label = "",
  required = false,
  reset,
  focusOnInput = false,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const { data, isLoading, error } = useGetMentionQuery(findSelf().lg_id);
  const [suggestions, setSuggestions] = useState([]);
  const [open, setOpen] = useState(false);
  const [editorState, setEditorState] = React.useState(
    EditorState.createEmpty()
  );
  const editorRef = React.useRef(null);
  const commentBox = React.useRef(null);

  useEffect(() => {
    if (commentBox && focusOnInput) {
      commentBox.current.querySelector("div").click();
    }
  }, [commentBox.current]);

  useEffect(() => {
    if (data) setSuggestions(data);
    // eslint-disable-next-line
  }, [data]);
  useEffect(() => {
    if (reset) setEditorState(EditorState.createEmpty());
    // eslint-disable-next-line
  }, [reset]);
  useEffect(() => {
    if (value !== "")
      setEditorState(
        EditorState.push(editorState, convertFromRaw(value, "update-state"))
      );
    // eslint-disable-next-line
  }, []);
  const { MentionSuggestions, plugins, EmojiSuggestions, EmojiSelect } =
    useMemo(() => {
      const emojiPlugin = createEmojiPlugin();
      const linkifyPlugin = createLinkifyPlugin();
      const mentionPlugin = createMentionPlugin();
      // eslint-disable-next-line no-shadow
      const { EmojiSuggestions, EmojiSelect } = emojiPlugin;
      const { MentionSuggestions } = mentionPlugin;
      // eslint-disable-next-line no-shadow
      const plugins = [mentionPlugin, emojiPlugin, linkifyPlugin];
      return {
        plugins,
        MentionSuggestions,
        EmojiSuggestions,
        EmojiSelect,
      };
    }, []);

  //-----------------------Declare function Here-----------------------------//

  const onOpenChange = useCallback((_open) => {
    setOpen(_open);
  }, []);
  const onSearchChange = useCallback(async () => {
    if (data) setSuggestions(data);
    // eslint-disable-next-line
  }, []);
  const handleOnChange = (newEditorState) => {
    setEditorState(newEditorState);
    onChange(convertToRaw(newEditorState.getCurrentContent()));
  };
  const getLengthOfSelectedText = () => {
    const currentSelection = editorState.getSelection();
    const isCollapsed = currentSelection.isCollapsed();
    let length = 0;
    if (!isCollapsed) {
      const currentContent = editorState.getCurrentContent();
      const startKey = currentSelection.getStartKey();
      const endKey = currentSelection.getEndKey();
      const startBlock = currentContent.getBlockForKey(startKey);
      const isStartAndEndBlockAreTheSame = startKey === endKey;
      const startBlockTextLength = startBlock.getLength();
      const startSelectedTextLength =
        startBlockTextLength - currentSelection.getStartOffset();
      const endSelectedTextLength = currentSelection.getEndOffset();
      const keyAfterEnd = currentContent.getKeyAfter(endKey);
      if (isStartAndEndBlockAreTheSame) {
        length +=
          currentSelection.getEndOffset() - currentSelection.getStartOffset();
      } else {
        let currentKey = startKey;
        while (currentKey && currentKey !== keyAfterEnd) {
          if (currentKey === startKey) {
            length += startSelectedTextLength + 1;
          } else if (currentKey === endKey) {
            length += endSelectedTextLength;
          } else {
            length += currentContent.getBlockForKey(currentKey).getLength() + 1;
          }
          currentKey = currentContent.getKeyAfter(currentKey);
        }
      }
    }

    return length;
  };
  const handleBeforeInput = () => {
    if (maxLength) {
      const currentContent = editorState.getCurrentContent();
      const currentContentLength = currentContent.getPlainText("").length;
      const selectedTextLength = getLengthOfSelectedText();
      if (currentContentLength - selectedTextLength > maxLength - 1) {
        return "handled";
      }
    }
  };
  const handleKeyCommand = (command, editorState) => {
    const newState = RichUtils.handleKeyCommand(editorState, command);
    if (newState) {
      onChangeValue(newState);
      return "handled";
    }
    return "not-handled";
  };
  const onChangeValue = (value) => {
    setEditorState(value);
  };

  return (
    <Box className="commentBox" sx={{ width: "100%" }}>
      <EmojiSelect />
      <EmojiSuggestions />
      <TextField
        ref={commentBox}
        fullWidth
        multiline
        minRows={3}
        maxRows={5}
        label={label}
        value="dummy"
        required={required}
        onChange={() => {}}
        error={errors === true ? true : false}
        helperText={errors ? errors?.message : null}
        inputProps={{
          maxLength: maxLength ? maxLength : null,
        }}
        InputProps={{
          inputProps: {
            component: Editor,
            editorRef,
            editorState,
            handleOnChange,
            handleKeyCommand,
            plugins,
            handleBeforeInput,
          },
          inputComponent: DraftField,
        }}
      />
      <MentionSuggestions
        open={open}
        onOpenChange={onOpenChange}
        suggestions={suggestions}
        onSearchChange={onSearchChange}
        onAddMention={() => {}}
      />
    </Box>
  );
};

export const AddComment = ({
  lg_id,
  entry_id,
  entry_type,
  focusOnInput = false,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [postComment] = usePostCommentMutation();
  const [reset, setReset] = useState(0);
  const { handleSubmit, control } = useForm({
    mode: "onChange",
  });

  //-----------------------Declare function Here-----------------------------//

  const onComment = (data) => {
    let self = findSelf();
    postComment({
      lg_id: lg_id,
      entry_id: entry_id,
      entry_type: entry_type,
      comment: data.comment,
      commenter_lg_id: self.lg_id,
      link: `////${self.lg_url}`,
      readURL: self.readURL,
      name: `${self.fname} ${self.lname}`,
    }).then(setReset((e) => e + 1));
  };

  return (
    <Stack
      direction={"row"}
      alignItems={"center"}
      sx={{ display: "flex", width: "100%", position: "relative" }}
      spacing={1}
    >
      <Controller
        name={"comment"}
        control={control}
        render={({ field, fieldState }) => (
          <CommentBox
            onChange={field.onChange}
            errors={fieldState}
            reset={reset}
            focusOnInput={focusOnInput}
          />
        )}
        rules={{
          validate: {
            length: (value) =>
              (value.blocks[0].text.length >= 1 &&
                value.blocks[0].text.length <= 250) ||
              t("display.text.error.maxCharacters", {
                param1: 250,
              }),
          },
        }}
      />
      <IconButton
        onClick={handleSubmit((data) => onComment(data))}
        sx={{ backgroundColor: "#FFC43A", color: "#222222" }}
      >
        <SendIcon sx={{ fontSize: "16px" }} />
      </IconButton>
    </Stack>
  );
};
