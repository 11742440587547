import * as React from "react";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import CloudUploadIcon from "@mui/icons-material/CloudUpload";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import EditIcon from "@mui/icons-material/Edit";
import { VisuallyHiddenInput } from "../utils/Theme";
import { Img } from "./Img";

export const FileUploadButton = React.forwardRef((props) => {
  const {
    type = "file",
    startIcon = <CloudUploadIcon />,
    accept = "image/*",
    onChange = () => {},
    children = <></>,
    value = false,
    deleteButton = false,
    onDelete = () => {},
    inputType = "",
    file_type = "NOFILE",
    readURL = "NOFILE",
    mode = "new",
  } = props;
  const renderCondition = () => {
    if (mode === "new") return !value;
    else {
      let a = inputType === file_type.split("/")[0];
      return !a;
    }
  };
  return (
    <Box
      sx={{
        width: "190px",
        height: "150px",
      }}
    >
      {renderCondition() ? (
        <Button
          sx={{
            width: "150px",
            height: "150px",
            backgroundColor: "#E6E6E6 !important",
            color: "#B9B9B9 !important",
          }}
          {...props}
          startIcon={startIcon}
          component={"label"}
        >
          {children}
          <VisuallyHiddenInput
            type={type}
            accept={accept}
            onChange={onChange}
          />
        </Button>
      ) : (
        <Stack spacing={1.5} direction={"row"}>
          <Box className="preview" sx={{ height: "150px", width: "150px" }}>
            <>
              {file_type.split("/")[0] === "image" ? (
                <Img
                  style={{ height: "150px", width: "150px" }}
                  src={readURL}
                  alt=""
                />
              ) : file_type.split("/")[0] === "video" ? (
                <video controls width="150" height="150" key={readURL}>
                  <source src={readURL} type="video/mp4" />
                </video>
              ) : file_type.split("/")[0] === "audio" ? (
                <audio
                  key={readURL}
                  controls
                  style={{
                    width: "125",
                    borderBottomLeftRadius: "20px",
                    borderBottomRightRadius: "20px",
                  }}
                >
                  <source key={readURL} src={readURL} type="audio/mpeg" />
                </audio>
              ) : file_type.split("/")[0] === "application" ? (
                <iframe width="150px" height="150px" src={readURL} />
              ) : null}
            </>
          </Box>
          <Box sx={{ position: "relative" }}>
            <Button
              sx={{
                minWidth: 0,
                p: 0,
                color: "#B9B9B9 !important",
                backgroundColor: "transparent !important",
                position: "relative",
                top: 0,
              }}
              {...props}
              startIcon={<EditIcon />}
              component={"label"}
            >
              <VisuallyHiddenInput
                type={type}
                accept={accept}
                onChange={onChange}
              />
            </Button>
            {deleteButton && (
              <Button
                sx={{
                  minWidth: 0,
                  p: 0,
                  color: "#B9B9B9 !important",
                  backgroundColor: "transparent !important",
                  position: "relative",
                  bottom: 0,
                }}
                {...props}
                startIcon={DeleteOutlineIcon}
                component={"label"}
              >
                <VisuallyHiddenInput
                  type={type}
                  accept={accept}
                  onChange={onDelete}
                />
              </Button>
            )}
          </Box>
        </Stack>
      )}
    </Box>
  );
});
