import { setAlert } from "../redux/slice/alert";
import { store } from "../redux/store";
import { baseURL } from "../utils/constants";

export const referrerApi = async (referrer, userId, token) => {
  const requestOptions = {
    method: "POST",
    headers: {
      "Content-Type": "application/json",
      UserId: userId,
      token: token,
    },
    body: JSON.stringify({ referrer: referrer }),
  };

  try {
    let api = fetch(`${baseURL}/referrer`, requestOptions)
      .then((response) => response.json())
      .then((responseJSON) => {
        if (!responseJSON.error) {
          return true;
        }
        store.dispatch(setAlert({ ...responseJSON }));
        return false;
      });
    return api;
  } catch (e) {
    console.error(e);
    return false; // { error: true, message: helperText.errors.apiFailed };
  }
};
