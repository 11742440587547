import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  open: false,
  lg_id: "",
  type: "",
};

export const addEventSlice = createSlice({
  name: "addEvent",
  initialState,
  reducers: {
    setAddEvent: (state, action) => {
      return { ...state, ...action.payload };
    },
    resetAddEvent: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const { setAddEvent, resetAddEvent } = addEventSlice.actions;

export default addEventSlice.reducer;
