import React, { useState } from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import { ReactionCount } from "../atoms/ReactionCount";
import { t } from "i18next";
import { findEventType } from "../../utils/functions";
import Avatar from "@mui/material/Avatar";
import Stack from "@mui/material/Stack";
import AvatarGroup from "@mui/material/AvatarGroup";
import { emoji } from "../../utils/ListItems";
import Typography from "@mui/material/Typography";
import Button from "@mui/material/Button";

export const ReactionViewer = ({
  uniq_reaction_list,
  lg_id,
  entry_id,
  entry_type,
  reaction_cnt,
  open = false,
  close = () => {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [openPromt, setOpen] = useState(open);
  const handleClose = () => {
    setOpen(false);
    close();
  };

  return (
    <>
      <Button sx={{ minHeight: 0, minWidth: 0, p: 0 }}>
        <Stack
          direction={"row"}
          sx={{
            "&:hover": { cursor: "pointer" },
            display: "flex",
            alignItems: "center",
          }}
          onClick={() => setOpen(true)}
        >
          <AvatarGroup>
            {uniq_reaction_list &&
              uniq_reaction_list.split(",").map((item, idx) => (
                <Avatar
                  sx={{
                    height: "16px",
                    width: "16px",
                    backgroundColor: "white.main",
                  }}
                  key={idx}
                  src={emoji[item]}
                />
              ))}
          </AvatarGroup>
          <Typography variant="caption" sx={{ fontSize: "14px" }}>
            {reaction_cnt}
          </Typography>
        </Stack>
      </Button>

      <CustomDialog
        open={openPromt}
        close={handleClose}
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t("display.text.text.reactions")}
          </Typography>
        }
        secondaryTitle={
          <Typography
            variant="caption"
            align="center"
            component={"div"}
            sx={{ fontSize: "14px" }}
          >
            {t(`display.text.button.${findEventType(entry_type).title}`, {
              count: 1,
            })}
          </Typography>
        }
      >
        <ReactionCount
          lg_id={lg_id}
          entry_id={entry_id}
          entry_type={entry_type}
        />
      </CustomDialog>
    </>
  );
};
