import React from "react";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Toolbar from "@mui/material/Toolbar";
import Button from "@mui/material/Button";
import Container from "@mui/material/Container";
import { useNavigate } from "react-router-dom";
import { FetchHtml } from "../atoms/FetchHtml";
import { t } from "i18next";
import { assetSource } from "../../utils/ListItems";
export const About = () => {
  const navigate = useNavigate();
  return (
    <Box>
      <AppBar
        color="white"
        sx={{
          borderStyle: "solid",
          borderColor: "border.main",
          borderWidth: 0,
          borderBottomWidth: "thin",
          position: "relative",
          top: 0,
        }}
      >
        <Toolbar>
          <Box sx={{ display: "flex", width: "100%", alignItems: "center" }}>
            <img
              src={`${assetSource}/logo_logo`}
              onClick={() => navigate("/")}
              style={{ cursor: "pointer" }}
            />
            <Box
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "flex-end",
                width: "100%",
              }}
            >
              <Button onClick={() => navigate("/login")}>
                {t("display.text.button.login")}
              </Button>
              <Button onClick={() => navigate("/signup")}>
                {t("display.text.button.signup")}
              </Button>
            </Box>
          </Box>
        </Toolbar>
      </AppBar>
      <Container sx={{ height: "90vh", maxWidth: { xs: "100%", md: "600px" } }}>
        <FetchHtml
          link={"https://stor1.famscape.com/openstore/textfiles/about.html"}
        />
      </Container>
    </Box>
  );
};
