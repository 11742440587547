import { setAlert } from "../redux/slice/alert";
import { store } from "../redux/store";
import { getApi } from "../utils/functions";

export const getDirectRelation = async (lgId) => {
  let response = await getApi("getDirRelations", `lg_id=${lgId}`);
  if (!response.error) {
    return response;
  } else {
    store.dispatch(setAlert(response));
    return response;
  }
};
