import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import { useNavigate } from "react-router-dom";
import { RichTextViewer } from "../../UI/RichTextViewer";
import { findDaysAndHours, findSelf } from "../../utils/functions";
import Stack from "@mui/material/Stack";
import IconButton from "@mui/material/IconButton";
import DeleteIcon from "@mui/icons-material/Delete";
import { DeleteEntryPromt } from "./DeleteEntryPromt";
import { t } from "i18next";
import { useDeleteCommentMutation } from "../../redux/slice/comment";
export const CommentPill = React.memo(
  ({
    readURL,
    link,
    name,
    lg, // commenter lg_id
    id,
    entry_id,
    entry_type,
    dt,
    desc,
    temp,
    user_connect_to_lg,
    lg_id,
  }) => {
    //-----------------------Declare Hooks Here-----------------------------//
    const navigate = useNavigate();
    const [onDelete] = useDeleteCommentMutation();
    const [open, setOpen] = useState(false);
    const entry_created_by = findSelf().lg_id === lg;
    const canDelete =
      user_connect_to_lg?.user_owner_type === "Self" ||
      user_connect_to_lg?.user_owner_type === "Owner"
        ? true
        : user_connect_to_lg?.user_owner_type === "Collab" && entry_created_by
        ? true
        : false;
    //-----------------------Declare Functions Here-----------------------------//

    const handleDelete = () => {
      onDelete({
        lg_id: lg_id,
        entry_id: entry_id,
        entry_type: entry_type,
        comment_id: id,
        entry_created_by: entry_created_by,
      });
    };

    return (
      <>
        <Stack direction={"row"} spacing={1} sx={{ my: 2 }}>
          <CustomAvatar src={readURL} />
          <Box>
            <Box
              sx={{
                // display: "flex",
                backgroundColor: "background.comment",
                borderRadius: "8px",
                p: 1,
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                }}
              >
                <Box>
                  <Typography
                    variant="body1"
                    sx={{
                      cursor: "pointer",
                      fontWeight: "bold",
                      overflow: "hidden",
                      textOverflow: "ellipsis",
                      textWrap: "nowrap",
                    }}
                    onClick={() =>
                      navigate(`/profile/${link.split("/")[4]}/about`)
                    }
                  >
                    {name}
                  </Typography>
                </Box>
                <Box sx={{ minWidth: "24px", minHeight: "16px", pl: 1 }}>
                  {canDelete && !temp && (
                    <IconButton sx={{ p: 0 }} onClick={() => setOpen(true)}>
                      <DeleteIcon sx={{ fontSize: "14px" }} />
                    </IconButton>
                  )}
                </Box>
              </Box>

              <RichTextViewer text={desc} truncate={false} truncateChar={0} />
            </Box>
            <Box>
              <Typography variant="caption">{findDaysAndHours(dt)}</Typography>
            </Box>
          </Box>
        </Stack>
        <DeleteEntryPromt
          open={open}
          close={setOpen}
          onDelete={handleDelete}
          onCancel={setOpen}
          message={t("display.text.text.deleteConfirmation", {
            param1: t("display.text.text.comment"),
          })}
          type={"error"}
        />
      </>
    );
  }
);
