import React from "react";
import Button from "@mui/material/Button";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import SettingsIcon from "@mui/icons-material/Settings";
import { useNavigate } from "react-router-dom";

export const SettingsButton = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  return (
    <Button
      color="inherit"
      sx={{ width: "100%" }}
      onClick={() => navigate("/settings")}
      startIcon={<SettingsIcon />}
    >
      <Typography>{t("display.text.button.settings")}</Typography>
    </Button>
  );
};
