import React from "react";
import { useDispatch, useSelector } from "react-redux";
import { NewLifeEvent } from "../forms/NewLifeEvent";
import { resetAddEvent } from "../../redux/slice/addevent";
import { NewMoments } from "../forms/NewMoments";
import { NewAchievement } from "../forms/NewAchievement";
import { NewAnnouncement } from "../forms/NewAnnouncement";
import { NewWish } from "../forms/NewWish";
import { NewWisdom } from "../forms/NewWisdom";
import { NewAnecdotes } from "../forms/NewAnecdotes";
import { NewRandomThoughts } from "../forms/NewRandomThoughts";
export const AddEventFromHomePage = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { open, lg_id, type } = useSelector((state) => state.addEvent);
  const dispatch = useDispatch();
  return (
    <>
      {open && (
        <>
          {type === "le" && (
            <NewLifeEvent
              open={type === "le"}
              close={() => dispatch(resetAddEvent())}
              lg_id={lg_id}
            />
          )}
          {type === "mem" && (
            <NewMoments
              open={type === "mem"}
              close={() => dispatch(resetAddEvent())}
              lg_id={lg_id}
            />
          )}
          {type === "ach" && (
            <NewAchievement
              open={type === "ach"}
              close={() => dispatch(resetAddEvent())}
              lg_id={lg_id}
            />
          )}
          {type === "anc" && (
            <NewAnnouncement
              open={type === "anc"}
              close={() => dispatch(resetAddEvent())}
              lg_id={lg_id}
            />
          )}
          {type === "random" && (
            <NewRandomThoughts
              open={type === "random"}
              close={() => dispatch(resetAddEvent())}
              lg_id={lg_id}
            />
          )}
          {type === "wish" && (
            <NewWish
              open={type === "wish"}
              close={() => dispatch(resetAddEvent())}
              lg_id={lg_id}
            />
          )}
          {type === "wisdom" && (
            <NewWisdom
              open={type === "wisdom"}
              close={() => dispatch(resetAddEvent())}
              lg_id={lg_id}
            />
          )}
          {type === "impress" && (
            <NewAnecdotes
              open={type === "impress"}
              close={() => dispatch(resetAddEvent())}
              lg_id={lg_id}
            />
          )}
        </>
      )}
    </>
  );
};
