import { createSlice } from "@reduxjs/toolkit";
import { chooseDevice, detectOS } from "../../utils/functions";
const initialState = {
  userAgent: detectOS(),
  device: chooseDevice(window.innerWidth),
  isAppReady: false,
  userStatus: "",
  inviteToken: "",
};

export const configSlice = createSlice({
  name: "config",
  initialState,
  reducers: {
    checkDevice: (state, action) => {
      state.device = chooseDevice(action.payload);
    },
    updateUserAgent: (state, action) => {
      state.userAgent = action.payload;
    },
    updateAppReady: (state, action) => {
      state.isAppReady = action.payload;
    },
    updateInviteToken: (state, action) => {
      state.inviteToken = action.payload;
    },
    updateUserStatus: (state, action) => {
      state.userStatus = action.payload;
    },
    resetConfig: () => initialState,
  },
});

// Action creators are generated for each case reducer function
export const {
  checkDevice,
  updateUserAgent,
  updateInviteToken,
  updateUserStatus,
  updateAppReady,
  resetConfig,
} = configSlice.actions;

export default configSlice.reducer;
