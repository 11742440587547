import { getApi } from "../utils/functions";

export const citiesApi = async (value) => {
  try {
    let responseJSON = await getApi("getCities", `city=${value}`);
    try {
      if (!responseJSON.error) {
        let cities = responseJSON.output.map((obj) =>
          Object.values(obj).join("-")
        );
        return cities;
      } else {
        return [];
      }
    } catch (e) {
      return [];
    }
  } catch (e) {
    return [];
  }
};
