import React from "react";
import { GoogleLogin } from "@react-oauth/google";
import { googleAuthenticationApi } from "../../api/googleAuthenticationApi";

const GoogleAuthentication = () => {
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("token");
  const onSuccessAuthentication = async (credentialResponse) => {
    googleAuthenticationApi(credentialResponse.credential, myParam);
  };
  return (
    <GoogleLogin
      onSuccess={(credentialResponse) => {
        onSuccessAuthentication(credentialResponse);
      }}
      onError={() => {
        console.log("Login Failed");
      }}
      useOneTap
      type="standard"
      shape="pill"
      logo_alignment="center"
      size="large"
      width="360px"
      text="continue_with"
    />
  );
};
export default GoogleAuthentication;
