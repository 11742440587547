import React, { useState } from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import { t } from "i18next";
import {
  findEventType,
  findSelf,
  findWho,
  isAlive,
  validatePlan,
} from "../../utils/functions";
import LoadingButton from "@mui/lab/LoadingButton";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { useForm, Controller } from "react-hook-form";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { wishTypeAlive, wishTypeDead } from "../../utils/ListItems";
import { useStyles } from "../../utils/Theme";
import {
  useGetWishDesignQuery,
  usePostWishMutation,
} from "../../redux/slice/wish";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import { GreetingCard } from "../../UI/greetingCard/GreetingCard";
import { UpgradePromt } from "../../UI/UpgradePromt";
import CustomAvatar from "../../UI/CustomAvathar";

export const NewWish = ({
  open,
  close,
  mode = "new",
  comment_cnt,
  created_lg,
  created_lg_url,
  desc,
  link,
  dt,
  entry_created_by,
  file_type,
  id,
  my_reaction,
  reaction_cnt,
  readURL,
  title,
  uniq_reaction_list,
  updated_date,
  visibility,
  searchValue = "",
  user_connect_to_lg,
  lg_id,
  fstr,
  typ = "wish",
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const classes = useStyles();
  const who = findWho(lg_id);
  const { data, isLoading } = useGetWishDesignQuery();
  const defaultValues = {
    wish_desc: "",
    sticker_name: "",
    wish_type: "",
    lg_id: lg_id,
    visibility: "dist",
  };
  const { handleSubmit, control, formState, getValues } = useForm({
    mode: "onChange",
    defaultValues,
  });
  const [plan, setPlan] = useState(mode === "new" ? validatePlan() : "active");
  const [currentPage, setCurrentPage] = useState("main");
  const [sticker, setSticker] = useState(null);
  const [_, setMainPageData] = useState(null);
  const [description, setDescription] = useState(null);
  const [self] = useState(findSelf());
  const [recieverName] = useState(findWho(lg_id));
  const [postWish, { isLoading: postinghWish }] = usePostWishMutation();

  //-----------------------Declare Function Here-----------------------------//

  const handleClose = () => {
    if (currentPage === "preview") setCurrentPage("main");
    else close();
  };
  const formatData = (data) => {
    if (currentPage === "main") {
      setCurrentPage("preview");
      setMainPageData(data);
    } else {
      let plan = validatePlan(typ, "none");
      if (plan === "upgrade") {
        setPlan(plan);
      } else {
        postWish({ ...data, sticker: sticker })
          .then(() => close())
          .catch(() => close());
      }
    }
  };

  return (
    <>
      {plan === "active" && (
        <CustomDialog
          open={open}
          close={handleClose}
          title={
            <Stack
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={1}
              direction={"row"}
            >
              <CustomAvatar src={who?.readURL} />
              <Box>
                <Typography
                  variant="h6"
                  component={"div"}
                  align="center"
                  sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
                >
                  {who?.name}
                </Typography>
                <Typography
                  variant="caption"
                  component={"div"}
                  sx={{ fontSize: "14px" }}
                >
                  {t(`display.text.button.${findEventType(typ).title}`, {
                    count: 1,
                  })}
                </Typography>
              </Box>
            </Stack>
          }
          // secondaryTitle={
          //   currentPage === "preview" ? t("display.text.text.preview") : null
          // }
          action={
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <LoadingButton
                variant="contained"
                disabled={!formState.isValid}
                onClick={handleSubmit((data) => formatData(data))}
                loading={postinghWish}
              >
                <Typography>
                  {t(
                    `display.text.button.${
                      currentPage === "main" ? "preview" : "sendwish"
                    }`
                  )}
                </Typography>
              </LoadingButton>
            </Box>
          }
        >
          <Card sx={{ width: "100%", p: 1 }}>
            {currentPage === "main" && (
              <Stack spacing={1}>
                <Controller
                  name={"wish_type"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <Autocomplete
                      clearOnBlur
                      {...field}
                      value={field.value}
                      options={isAlive(lg_id) ? wishTypeAlive : wishTypeDead}
                      autoHighlight={true}
                      freeSolo={false}
                      isOptionEqualToValue={(option, value) => option === value}
                      renderOption={(props, option) => (
                        <Box component="li" {...props}>
                          {option.label}
                        </Box>
                      )}
                      renderInput={(params) => (
                        <Box>
                          <TextField
                            {...params}
                            label={t("display.text.inputField.wishType")}
                            required={true}
                            error={fieldState.error ? true : false}
                            helperText={
                              fieldState.error ? fieldState.error.message : null
                            }
                          />
                        </Box>
                      )}
                      onChange={(_, data) => field.onChange(data)}
                    />
                  )}
                  rules={{
                    required: {
                      value: true,
                      message: t("display.text.error.fieldIsRequired", {
                        param1: t("display.text.inputField.eventType"),
                      }),
                    },
                  }}
                />
                <Card
                  sx={{
                    maxHeight: { xs: "375px", sm: "250px" },
                    minHeight: { xs: "375px", sm: "250px" },
                    overflowY: "scroll",
                    display: "flex",
                    flexWrap: "wrap",
                    p: 1,
                    backgroundColor: "background.dark",
                  }}
                >
                  {data && (
                    <Stack spacing={1}>
                      <Stack
                        spacing={1}
                        direction={"row"}
                        sx={{
                          display: "flex",
                          flexWrap: "wrap",
                          //   justifyContent: "center",
                          overflowY: "scroll",
                        }}
                        className={classes.root}
                      >
                        {data &&
                          data.fileNameArr.map((item) => (
                            <Controller
                              name={"sticker_name"}
                              control={control}
                              render={({ field }) => (
                                <Button
                                  sx={{
                                    display: "flex",
                                    justifyContent: "center",
                                    backgroundColor:
                                      item.fileName === sticker?.fileName
                                        ? "background.selected"
                                        : "",
                                  }}
                                  onClick={() => {
                                    setSticker(item);
                                    field.onChange(item.fileName);
                                  }}
                                >
                                  <img
                                    style={{
                                      height: "100px",
                                      width: "80px",
                                      opacity: 1,
                                    }}
                                    src={`${data.bucketLink}/${item.fileName}`}
                                    alt=""
                                    loading="lazy"
                                  />
                                </Button>
                              )}
                              rules={{
                                required: {
                                  value: true,
                                  message: t(
                                    "display.text.error.fieldIsRequired",
                                    {
                                      param1: t(
                                        "display.text.inputField.eventType"
                                      ),
                                    }
                                  ),
                                },
                              }}
                            />
                          ))}
                      </Stack>
                    </Stack>
                  )}
                </Card>
              </Stack>
            )}
            {currentPage === "preview" && (
              <Stack spacing={1}>
                <GreetingCard
                  wish_style={sticker.wish_style}
                  baseURL={data.bucketLink}
                  sticker_name={getValues("sticker_name")}
                  wish_desc={description}
                  created_date={new Date()}
                  creator_name={`${self?.fname} ${self?.lname}`}
                  recieverName={recieverName?.name}
                  flipValue={Boolean(description)}
                />
                <Typography variant="caption">
                  {t("display.text.text.tapToTurnCard")}
                </Typography>
                <Controller
                  name={"wish_desc"}
                  control={control}
                  render={({ field, fieldState }) => (
                    <TextField
                      {...field}
                      onChange={(e) => {
                        setDescription(e.target.value);
                        field.onChange(e.target.value);
                      }}
                      label={t("display.text.inputField.description")}
                      type={"text"}
                      inputProps={{
                        maxLength: 1001,
                      }}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error ? fieldState.error.message : null
                      }
                      InputProps={{
                        inputComponent: TextareaAutosize,
                      }}
                      sx={{ width: "100%" }}
                    />
                  )}
                  rules={{
                    maxLength: {
                      value: 1000,
                      message: t("display.text.error.maxCharacters", {
                        param1: 1000,
                      }),
                    },
                    required: {
                      value: true,
                      message: t("display.text.error.fieldIsRequired", {
                        param1: t("display.text.inputField.description"),
                      }),
                    },
                  }}
                />
              </Stack>
            )}
          </Card>
        </CustomDialog>
      )}
      {plan === "upgrade" && (
        <>
          <UpgradePromt
            open={plan === "upgrade"}
            close={() => {
              close(false);
            }}
          />
        </>
      )}
    </>
  );
};
