import React from "react";
import { CustomDialog } from "../../UI/CustomDialog";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import TextField from "@mui/material/TextField";
import TextareaAutosize from "@mui/material/TextareaAutosize";
import MenuItem from "@mui/material/MenuItem";
import Autocomplete from "@mui/material/Autocomplete";
import Chip from "@mui/material/Chip";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import { t } from "i18next";
import { useForm, Controller } from "react-hook-form";
import { HelperIcon } from "../atoms/HelperIcon";
import { VisiblityHelperText } from "../atoms/VisiblityHelperText";
import { useStyles } from "../../utils/Theme";
import LoadingButton from "@mui/lab/LoadingButton";
import { useSelector } from "react-redux";
import {
  formatFreeSoloChipInput,
  getLabelAndValue,
} from "../../utils/functions";
import {
  educationAreaList,
  occupationList,
  visibilityTypes,
} from "../../utils/ListItems";
import { useEditLgAboutMutation } from "../../redux/slice/lgabout";

export const LgOccupation = ({
  open,
  close = () => {},
  mode,
  main_occupation = null,
  sec_occupation = null,
  occu_details = null,
  wrk_visib = "dist",
  workplaces = null,
  lg_id = "",
  lg_url = "",
  onNext = () => {},
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const defaultValues = {
    main_occupation: main_occupation !== null ? main_occupation.split("|") : [],
    sec_occupation: sec_occupation !== null ? sec_occupation.split("|") : [],
    occu_details: occu_details,
    wrk_visib: wrk_visib === null ? "dist" : wrk_visib,
    workplaces: workplaces !== null ? workplaces.split("|") : [],
    lg_id: lg_id,
    url: "lgWork",
    lg_url: lg_url,
  };
  const { device } = useSelector((state) => state.config);
  const mobile = device === "xs" || device === "sm" || device === "md";
  const { handleSubmit, control, formState } = useForm({
    mode: "onBlur",
    defaultValues,
  });
  const [editLg, { isLoading }] = useEditLgAboutMutation();
  const classes = useStyles();

  //-----------------------Declare Functions Here-----------------------------//

  const formatData = (data) => {
    data.main_occupation =
      data.main_occupation.length > 0 ? data.main_occupation.join("|") : null;
    data.sec_occupation =
      data.sec_occupation.length > 0 ? data.sec_occupation.join("|") : null;
    data.workplaces =
      data.workplaces.length > 0 ? data.workplaces.join("|") : null;
    editLg(data)
      .then(() => {
        if (mode === "self" || mode === "new") {
          onNext();
        } else {
          close();
        }
      })
      .catch(() => close());
  };
  let dummy = {
    isValid: formState.isValid,
    isDirty: formState.isDirty,
  };

  return (
    <>
      <CustomDialog
        open={open}
        iconPlacement={mode === "self" ? "no" : "left"}
        close={close}
        title={
          <Typography
            variant="h6"
            sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
            align="center"
          >
            {t("display.text.text.editModeLgOccupationTitle")}
          </Typography>
        }
        action={
          <Box
            sx={{ width: "100%", display: "flex", justifyContent: "center" }}
          >
            <LoadingButton
              variant="contained"
              loading={isLoading}
              disabled={!(formState.isDirty && formState.isValid)}
              onClick={handleSubmit((data) => formatData(data))}
            >
              {t("display.text.button.save")}
            </LoadingButton>
          </Box>
        }
      >
        <Card sx={{ width: "100%", p: 1 }}>
          <Stack spacing={2}>
            <Controller
              name={"workplaces"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={[]}
                  freeSolo={true}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");
                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, []);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("display.text.inputField.OrganizationWorkedIn")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("display.text.helperText.chipMobile")
                          : t("display.text.helperText.chipDesktop")
                      }
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("display.text.error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${
                        t("display.text.error.noMoreChar").split(" ")[6]
                      }`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 15 ||
                    `${
                      t("display.text.error.maxChipEntryError").split(" ")[0]
                    } 15 ${t("display.text.error.maxChipEntryError").slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("display.text.error.pipeOperatorError"),
                },
              }}
            />
            <Controller
              name={"main_occupation"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={occupationList}
                  freeSolo={true}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(
                        option,
                        educationAreaList
                      );
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("display.text.inputField.primaryOccupation")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("display.text.helperText.chipMobile")
                          : t("display.text.helperText.chipDesktop")
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            <HelperIcon
                              helperText={
                                <Typography variant="body1">
                                  {t(
                                    "display.text.helperText.primaryOccupation"
                                  )}
                                </Typography>
                              }
                            />
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 50) ||
                      `${t("display.text.error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 50 ${
                        t("display.text.error.noMoreChar").split(" ")[6]
                      }`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 15 ||
                    `${
                      t("display.text.error.maxChipEntryError").split(" ")[0]
                    } 15 ${t("display.text.error.maxChipEntryError").slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("display.text.error.pipeOperatorError"),
                },
              }}
            />
            <Controller
              name={"sec_occupation"}
              control={control}
              render={({ field, fieldState }) => (
                <Autocomplete
                  clearOnBlur
                  {...field}
                  multiple
                  options={occupationList}
                  freeSolo={true}
                  onChange={(_, data) =>
                    field.onChange(formatFreeSoloChipInput(data))
                  }
                  onInputChange={(_, newInputValue) => {
                    const options = newInputValue.split(",");
                    if (options.length > 1) {
                      field.onChange(
                        field.value
                          .concat(options)
                          .map((x) => x.trim())
                          .filter((x) => x)
                      );
                    }
                  }}
                  renderTags={(value, getTagProps) =>
                    value.map((option, index) => {
                      let getLabel = getLabelAndValue(option, []);
                      return (
                        <Chip
                          variant="outlined"
                          label={getLabel ? getLabel.label : option}
                          {...getTagProps({ index })}
                        />
                      );
                    })
                  }
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t("display.text.inputField.secondaryOccupation")}
                      error={fieldState.error ? true : false}
                      helperText={
                        fieldState.error
                          ? fieldState.error.message
                          : mobile
                          ? t("display.text.helperText.chipMobile")
                          : t("display.text.helperText.chipDesktop")
                      }
                      InputProps={{
                        ...params.InputProps,
                        endAdornment: (
                          <>
                            <HelperIcon
                              helperText={
                                <Typography variant="body1">
                                  {t(
                                    "display.text.helperText.secondaryOccupation"
                                  )}
                                </Typography>
                              }
                            />
                            {params.InputProps.endAdornment}
                          </>
                        ),
                      }}
                      // placeholder="Favorites"
                    />
                  )}
                />
              )}
              rules={{
                validate: {
                  lengthOfEachChip: (value) => {
                    return (
                      value.every((item) => item.length <= 75) ||
                      `${t("display.text.error.noMoreChar")
                        .split(" ")
                        .slice(0, 6)
                        .join(" ")} 75 ${
                        t("display.text.error.noMoreChar").split(" ")[6]
                      }`
                    );
                  },
                  chipArrayCount: (value) =>
                    value.length <= 12 ||
                    `${
                      t("display.text.error.maxChipEntryError").split(" ")[0]
                    } 12 ${t("display.text.error.maxChipEntryError").slice(5)}`,
                  checkforspecialCharacters: (value) =>
                    value.every((item) => item.includes("|") === false) ||
                    t("display.text.error.pipeOperatorError"),
                },
              }}
            />
            <Controller
              name={"occu_details"}
              control={control}
              defaultValue={defaultValues.edu_details}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  label={t("display.text.inputField.AdditionalDetails")}
                  type={"text"}
                  inputProps={{
                    maxLength: 1001,
                  }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                  InputProps={{
                    inputComponent: TextareaAutosize,
                    endAdornment: (
                      <HelperIcon
                        helperText={
                          <Typography variant="body1">
                            {t("display.text.helperText.additionalDetails")}
                          </Typography>
                        }
                      />
                    ),
                  }}
                  sx={{ width: "100%" }}
                />
              )}
              rules={{
                maxLength: {
                  value: 1000,
                  message: t("display.text.error.maxCharacters", {
                    param1: 1000,
                  }),
                },
              }}
            />
            <Controller
              name={"wrk_visib"}
              control={control}
              defaultValue={defaultValues.edu_visib}
              render={({ field, fieldState }) => (
                <TextField
                  {...field}
                  classes={{
                    root: classes.textFieldRoot, // apply class here
                  }}
                  select={true}
                  label={t("display.text.inputField.Visiblity")}
                  value={field.value}
                  required={true}
                  InputProps={{
                    endAdornment: (
                      <HelperIcon helperText={<VisiblityHelperText />} />
                    ),
                  }}
                  sx={{ width: "100%" }}
                  error={fieldState.error ? true : false}
                  helperText={
                    fieldState.error ? fieldState.error.message : null
                  }
                >
                  {visibilityTypes.map((option, index) => (
                    <MenuItem key={index} value={option.value}>
                      {option.label}
                    </MenuItem>
                  ))}
                </TextField>
              )}
              rules={{
                required: {
                  value: true,
                  message: t("display.text.error.fieldIsRequired"),
                },
              }}
            />
          </Stack>
        </Card>
      </CustomDialog>
    </>
  );
};
