import React from "react";
import { useState } from "react";
import LoadingButton from "@mui/lab/LoadingButton";
import Box from "@mui/system/Box";
import { useForm, Controller } from "react-hook-form";
import { Link, useLocation } from "react-router-dom";
import { regularExpressions } from "../../utils/constants";
import { signupApi } from "../../api/signupApi";
import TextField from "@mui/material/TextField";
import Stack from "@mui/material/Stack";
import { t } from "i18next";
import Typography from "@mui/material/Typography";

const defaultValues = {
  emailid: "",
  password: "",
  cpassword: "",
};

const SignUpForm = ({ verifyEmail }) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const [loading, setLoading] = useState(false);
  const location = useLocation();
  const { handleSubmit, control, getValues, formState } = useForm({
    mode: "onChange",
    defaultValues,
  });

  //-----------------------Declare Functions Here-----------------------------//
  const formSubmit = async (data) => {
    if (formState.errors) {
      setLoading(true);
      const urlParams = new URLSearchParams(window.location.search);
      const myParam = urlParams.get("token");
      data.referrer = myParam !== null ? myParam : "";
      let api = signupApi(data);
      api.then((response) => {
        if (response) {
          setLoading(false);
          verifyEmail(response);
        } else setLoading(false);
      });
    }
  };
  const handelKeyDown = (event) => {
    if (event.keyCode === 13) {
      handleSubmit((data) => formSubmit(data))();
    }
  };

  return (
    <Box sx={{ width: "100%" }}>
      <Stack spacing={5} sx={{ width: "100%" }}>
        <Stack spacing={2} sx={{ width: "100%" }}>
          <Controller
            name={"emailid"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                required={true}
                label={t("display.text.inputField.email")}
                type={"email"}
                variant={"outlined"}
                error={fieldState.error ? true : false}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={{ width: "100%" }}
                onKeyDown={handelKeyDown}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t("display.text.error.fieldIsRequired", {
                  param1: t("display.text.inputField.email"),
                }),
              },
              pattern: {
                value: regularExpressions.email,
                message: t("display.text.error.emailValidationErr"),
              },
            }}
          />
          <Controller
            name={"password"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("display.text.inputField.password")}
                required={true}
                type={"password"}
                variant={"outlined"}
                error={fieldState.error ? true : false}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={{ width: "100%" }}
                onKeyDown={handelKeyDown}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t("display.text.error.fieldIsRequired", {
                  param1: t("display.text.inputField.password"),
                }),
              },
              pattern: {
                value: regularExpressions.password,
                message: t("display.text.error.passwordValidationErr"),
              },
            }}
          />
          <Controller
            name={"cpassword"}
            control={control}
            render={({ field, fieldState }) => (
              <TextField
                {...field}
                label={t("display.text.inputField.confirmPassword")}
                type={"password"}
                required={true}
                variant={"outlined"}
                error={fieldState.error ? true : false}
                helperText={fieldState.error ? fieldState.error.message : null}
                sx={{ width: "100%" }}
                onKeyDown={handelKeyDown}
              />
            )}
            rules={{
              required: {
                value: true,
                message: t("display.text.error.fieldIsRequired", {
                  param1: t("display.text.inputField.confirmPassword"),
                }),
              },
              validate: {
                positive: (value) =>
                  getValues("password") === value ||
                  t("display.text.error.passwordMissMatchErr"),
              },
            }}
          />
        </Stack>
        <Typography align="center">
          {t("display.text.text.termsAndCondition")}
          <Link to="/terms">{t("display.text.button.TermsofService")}</Link>
        </Typography>
        <LoadingButton
          variant="contained"
          type={"submit"}
          sx={{ width: "100%", my: 2, fontFamily: "Lato", fontSize: "16px" }}
          loading={loading}
          onClick={handleSubmit((data) => formSubmit(data))}
        >
          {t("display.text.button.signup1")}
        </LoadingButton>
        <Typography align="center">
          {t("display.text.text.alreadyUser")}
          <Link
            style={{ textDecoration: "none" }}
            to={`/login${location.search}`}
          >
            {t("display.text.button.loginHere")}
          </Link>
        </Typography>
      </Stack>
    </Box>
  );
};
export default SignUpForm;
