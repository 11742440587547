import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { t } from "i18next";

export const VisiblityHelperText = () => {
  return (
    <>
      <Typography variant="body1">
        {t("display.text.helperText.Visibility").split("|")[0]}
      </Typography>
      <ul>
        <li>
          <Typography variant="body2">
            <Box sx={{ fontWeight: "bold" }} component={"span"}>
              {t("display.text.helperText.Visibility")
                .split("|")[1]
                .split(" ")
                .slice(0, 1)}
            </Box>
            {t("display.text.helperText.Visibility")
              .split("|")[1]
              .split(" ")
              .slice(1)
              .join(" ")}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <Box sx={{ fontWeight: "bold" }} component={"span"}>
              {t("display.text.helperText.Visibility")
                .split("|")[2]
                .split(" ")
                .slice(0, 1)}
            </Box>
            {t("display.text.helperText.Visibility")
              .split("|")[2]
              .split(" ")
              .slice(1)
              .join(" ")}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <Box sx={{ fontWeight: "bold" }} component={"span"}>
              {t("display.text.helperText.Visibility")
                .split("|")[3]
                .split(" ")
                .slice(0, 2)}
            </Box>
            {t("display.text.helperText.Visibility")
              .split("|")[3]
              .split(" ")
              .slice(2)
              .join(" ")}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <Box sx={{ fontWeight: "bold" }} component={"span"}>
              {t("display.text.helperText.Visibility")
                .split("|")[4]
                .split(" ")
                .slice(0, 2)}
            </Box>
            {t("display.text.helperText.Visibility")
              .split("|")[4]
              .split(" ")
              .slice(2)
              .join(" ")}
          </Typography>
        </li>
        <li>
          <Typography variant="body2">
            <Box sx={{ fontWeight: "bold" }} component={"span"}>
              {t("display.text.helperText.Visibility")
                .split("|")[5]
                .split(" ")
                .slice(0, 1)}
            </Box>
            {t("display.text.helperText.Visibility")
              .split("|")[5]
              .split(" ")
              .slice(1)
              .join(" ")}
          </Typography>
        </li>
      </ul>
      <Typography variant="body1">
        {t("display.text.helperText.Visibility").split("|")[6]}
      </Typography>
    </>
  );
};
