import React, { useState, useRef, useEffect } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Button from "@mui/material/Button";
import { HomeFeedSkleton } from "../Skleton/HomeFeedSkleton";
import { Feed } from "../molecules/Feed";
import { PossibleRelation } from "./PossibleRelation";
import { t } from "i18next";
import { findSelf } from "../../utils/functions";
import { CreateNewEvent } from "../molecules/CreateNewEvent";
import { useSelector } from "react-redux";
import { ActivateLifograf } from "../molecules/ActivateLifograf";
import { BusyPointer } from "../../UI/BusyPointer";
import { IntroCardHomeFeed } from "../atoms/IntroCardHomeFeed";

export const HomePage = ({ data, trigger, isLoading }) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const random = useRef(
    Math.floor(Math.random() * data?.homeFeedList.length) || 0
  );
  const self = findSelf();
  const [open, setOpen] = useState(false);
  const [length, setLength] = useState(data.homeFeedList.length);
  const [hasMore, setHasMore] = useState(true);
  const { userStatus, device } = useSelector((state) => state.config);
  const loaderRef = useRef();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const target = entries[0];
      if (target.isIntersecting) {
        trigger(
          data.homeFeedList[data.homeFeedList.length - 1]?.sort_date
        ).then((e) => {
          if (e.data.homeFeedList.length - length < 15) {
            setHasMore(false);
          }
          setLength(e.data.homeFeedList.length);
        });
      }
    });

    if (loaderRef.current) {
      observer.observe(loaderRef.current);
    }

    return () => {
      if (loaderRef.current) {
        observer.unobserve(loaderRef.current);
      }
    };
  }, [length]);

  //-----------------------Declare Function Here-----------------------------//

  return (
    <>
      {(isLoading || !data) && <HomeFeedSkleton />}
      {!isLoading && data && (
        <InfiniteScroll
          dataLength={data?.homeFeedList.length - 5} //This is important field to render the next data
          hasMore={hasMore}
          loader={
            <div
              style={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
              }}
              ref={loaderRef}
            >
              <BusyPointer />
            </div>
          }
          endMessage={
            <p style={{ textAlign: "center" }}>
              {userStatus === "active" && (
                <>
                  {data?.homeFeedList.length > 0 ? (
                    <b>{t("display.text.text.endOfFeed")}</b>
                  ) : (
                    <b>{t("display.text.text.noFeed")}</b>
                  )}
                </>
              )}
            </p>
          }
        >
          <Stack spacing={1}>
            {userStatus === "inactive" ? (
              <ActivateLifograf />
            ) : (
              <>
                {device !== "sm" && (
                  <Card sx={{ width: "100%", p: 0.5 }}>
                    <Stack spacing={1}>
                      <Button
                        sx={{
                          backgroundColor: "white.main",
                          color: "grey.text",
                          display: "flex",
                          width: "100%",
                          justifyContent: "flex-start",
                          textTransform: "none",
                          fontSize: "14px",
                        }}
                        onClick={() => setOpen(true)}
                      >
                        {t("display.text.text.startTyping", {
                          param1: `${self?.fname}`,
                        })}
                      </Button>
                    </Stack>
                  </Card>
                )}
                <IntroCardHomeFeed addPost={(e) => setOpen(e)} />
              </>
            )}
            {data.homeFeedList.length > 0
              ? data.homeFeedList.map((item, idx) => (
                  <Box key={idx} sx={{ p: 0.5 }}>
                    <Stack spacing={1}>
                      <Feed data={item} />
                      {idx === random.current &&
                        data.potentialRelativesList.length > 0 && (
                          <PossibleRelation
                            PossibleRelationArr={data.potentialRelativesList}
                          />
                        )}
                    </Stack>
                  </Box>
                ))
              : data.potentialRelativesList.length > 0 && (
                  <PossibleRelation
                    PossibleRelationArr={data.potentialRelativesList}
                  />
                )}
          </Stack>
        </InfiniteScroll>
      )}
      {open && <CreateNewEvent open={open} close={() => setOpen(false)} />}
    </>
  );
};
