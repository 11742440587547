import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Box from "@mui/material/Box";
import React from "react";
export const ReactionCountSkleton = () => {
  return (
    <Stack
      spacing={1}
      direction="row"
      sx={{
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
      }}
    >
      <Stack
        spacing={1}
        direction={"row"}
        sx={{
          display: "flex",
          justifyContent: "flex-start",
          alignItems: "center",
          width: "100%",
          py: 1,
        }}
      >
        <Skeleton variant="circular" width={50} height={45} />
        <Box style={{ width: "100%" }}>
          <Skeleton variant="text" width={"30%"} sx={{ fontSize: "1rem" }} />
          <Skeleton variant="text" width={"5%"} sx={{ fontSize: "0.75rem" }} />
        </Box>
      </Stack>
      <Skeleton variant="rounded" width={30} height={30} />
    </Stack>
  );
};
