import { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { emailVerificationApi } from "../../api/emailVerificationApi";
export const EmailVerification = () => {
  const { urlParams } = useParams();
  console.log(urlParams);
  const navigate = useNavigate();
  useEffect(() => {
    let api = emailVerificationApi({
      emailid: urlParams.split("|")[0],
      verTok: urlParams.split("|")[1],
    });

    api.then((response) => {
      if (response) navigate("/");
      else navigate("/login");
    });
  }, []);

  return;
};
