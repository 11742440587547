import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React from "react";
import { useSelector } from "react-redux";
import { formatDate, isYoutubeLink } from "../../utils/functions";
import Highlighter from "react-highlight-words";
import { RichTextViewer } from "../../UI/RichTextViewer";
import { ReactionViewer } from "../molecules/ReactionViewer";
import Button from "@mui/material/Button";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Stack from "@mui/material/Stack";
import { ViewComments } from "../molecules/ViewComments";
import { Img } from "../../UI/Img";
import OpenInNewIcon from "@mui/icons-material/OpenInNew";
import { regularExpressions } from "../../utils/constants";

export const MediaGrid = ({
  comment_cnt,
  created_lg,
  created_lg_url,
  desc,
  dt,
  entry_created_by,
  file_type,
  id,
  my_reaction,
  reaction_cnt,
  readURL,
  title,
  type,
  uniq_reaction_list,
  updated_date,
  visibility,
  searchValue = "",
  user_connect_to_lg,
  lg_id,
  fstr,
  typ,
  link,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { device } = useSelector((state) => state.config);
  const { userStatus } = useSelector((state) => state.config);
  const isMobile = device === "xs" || device === "sm";

  //-----------------------Declare function Here-----------------------------//

  return (
    <Card
      sx={{
        width: "100%",
        p: 0.5,
      }}
    >
      <Stack spacing={0.75}>
        <Grid container>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              // height: "400px",
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "black.main",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            {file_type && (
              <Box sx={{ width: "100%" }}>
                {file_type.split("/")[0] === "image" ? (
                  <Box
                    sx={{
                      minHeight: "400px",
                      width: "100%",
                      display: "flex",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <Img
                      style={{
                        width: "100%", // isMobile ? "" : "100%",
                        height: "100%", // isMobile ? "256px" : "100%",
                        maxHeight: "100%", // isMobile ? "350px" : "256px",
                        objectFit: "contain",
                      }}
                      loading="lazy"
                      src={readURL}
                      alt="achievement"
                    />
                  </Box>
                ) : file_type.split("/")[0] === "video" ? (
                  <video
                    width="100%"
                    style={{ maxHeight: "480px" }}
                    key={readURL}
                    controls
                  >
                    <source src={readURL} type="video/mp4" />
                    Your browser does not support the video tag.
                  </video>
                ) : file_type.split("/")[0] === "audio" ? (
                  <Box sx={{ p: 1 }}>
                    <audio
                      controls
                      style={{
                        width: "100%",
                        borderBottomLeftRadius: "20px",
                        borderBottomRightRadius: "20px",
                      }}
                    >
                      <source src={readURL} type="audio/mpeg" />
                      Your browser does not support the video tag.
                    </audio>
                  </Box>
                ) : file_type.split("/")[0] === "application" ? (
                  <Box sx={{ p: 1 }}>
                    <iframe
                      width="100%"
                      height="480px"
                      src={readURL}
                      allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                      allowFullScreen
                      title="Embedded youtube"
                    />
                  </Box>
                ) : file_type.split("/")[0] === "NOFILE" && link ? (
                  <>
                    {isYoutubeLink(link) ? (
                      <iframe
                        style={
                          {
                            // borderBottomLeftRadius: "4px",
                            // borderBottomRightRadius: "4px",
                          }
                        }
                        width="100%"
                        height="480"
                        src={`https://www.youtube.com/embed/${
                          link.match(regularExpressions.isYoutubeLink)[1]
                        }`}
                        // frameBorder="0"
                        allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                        allowFullScreen
                        title="Embedded youtube"
                      />
                    ) : (
                      <Box sx={{ p: 1 }}>
                        <Button
                          color="error"
                          sx={{ borderRadius: 1, width: "100%" }}
                          variant="outlined"
                          startIcon={<OpenInNewIcon />}
                          onClick={() => window.open(link, "_blank")}
                        >
                          Open Link{" "}
                        </Button>
                      </Box>
                    )}
                  </>
                ) : null}
              </Box>
            )}
          </Grid>
          <Grid
            sx={{
              pl: { xs: 0, md: 1 },
              height: { xs: "fit-content", sm: "100%" },
              position: "relative",
            }}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box>
                  {searchValue ||
                    (title && (
                      <Typography variant="h6" fontWeight={600}>
                        {!searchValue ? (
                          title
                        ) : (
                          <Highlighter
                            highlightClassName="YourHighlightClass"
                            searchWords={searchValue.split(" ")}
                            autoEscape={true}
                            textToHighlight={title}
                          />
                        )}
                      </Typography>
                    ))}
                </Box>
              </Box>
              {dt && (
                <Typography variant="caption">{formatDate(dt)}</Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {searchValue ||
                  (desc && (
                    <Typography variant="body1" component={"div"}>
                      {!searchValue ? (
                        <RichTextViewer
                          text={desc}
                          truncate={false}
                          truncateChar={0}
                        />
                      ) : (
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchValue.split(" ")}
                          autoEscape={true}
                          textToHighlight={desc.blocks[0].text}
                        />
                      )}
                    </Typography>
                  ))}
              </Box>
            </Box>
          </Grid>
          <Grid
            sx={{
              pl: { xs: 0, md: 1 },
              height: { xs: "fit-content", sm: "100%" },
              position: "relative",
            }}
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
          >
            {(Boolean(Number(reaction_cnt)) ||
              Boolean(Number(comment_cnt))) && (
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  {Boolean(Number(reaction_cnt)) && (
                    <ReactionViewer
                      reaction_cnt={reaction_cnt}
                      uniq_reaction_list={uniq_reaction_list}
                      entry_id={id}
                      entry_type={typ}
                      lg_id={lg_id}
                    />
                  )}
                </Box>
                <Box sx={{ justifyContent: "flex-end" }}>
                  <Button
                    sx={{
                      minHeight: 0,
                      minWidth: 0,
                      p: 0,
                      color: "grey.text",
                    }}
                  >
                    <Stack
                      direction={"row"}
                      alignItems={"center"}
                      spacing={0.25}
                    >
                      <Typography variant="caption">{comment_cnt}</Typography>
                      <ChatBubbleOutlineIcon sx={{ fontSize: "0.75rem" }} />
                    </Stack>
                  </Button>
                </Box>
              </Box>
            )}
          </Grid>
        </Grid>
        <ViewComments
          lg_id={lg_id}
          entry_id={id}
          entry_type={typ}
          user_connect_to_lg={user_connect_to_lg}
        />
        <Box
          sx={{
            position: "sticky",
            bottom: 0,
            left: 0,
            right: 0,
            m: 0,
            p: 2,
            borderWidth: 0,
            borderTopWidth: 1,
          }}
        ></Box>
      </Stack>
    </Card>
  );
};
