import React from "react";
import { findSelf } from "../../utils/functions";
import { FamilyTree } from "../organism/FamilyTree";
import Card from "@mui/material/Card";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { useSelector } from "react-redux";

export const Explore = () => {
  let self = findSelf();
  const { device } = useSelector((state) => state.config);

  return (
    <Box>
      {device !== "sm" && (
        <Stack spacing={1.5}>
          <Card
            sx={{
              borderBottomLeftRadius: 0,
              borderBottomRightRadius: 0,
              width: "100%",
              display: "flex",
              justifyContent: "center",
              py: 1,
            }}
          >
            <Typography
              sx={{
                lineHeight: 1.5,
                fontSize: "20px",
                fontWeight: 400,
                color: "#333333",
              }}
            >
              {t("display.text.text.familyTree", { param1: self?.fname })}
            </Typography>
          </Card>
        </Stack>
      )}
      {device !== "sm" && <Divider></Divider>}
      <Card
        sx={{
          borderTopLeftRadius: 0,
          borderTopRightRadius: 0,
          height: "86vh",
          width: "100%",
        }}
      >
        <FamilyTree lg_id={self?.lg_id} />
      </Card>
    </Box>
  );
};
