import React, { useState } from "react";
import { useGetRelationRequestQuery } from "../../redux/slice/relationRequest";
import { theme } from "../../utils/Theme";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import Divider from "@mui/material/Divider";
import AppBar from "@mui/material/AppBar";
import SwipeableViews from "react-swipeable-views";
import { t } from "i18next";
import { TabPanel } from "../atoms/TabPanel";
import Badge from "@mui/material/Badge";
import { RelationRequestReceivedCard } from "../molecules/RelationRequestReceivedCard";
import { RelationRequestSentCard } from "../molecules/RelationRequestSentCard";
import { RelationRequestSkleton } from "../Skleton/RelationRequestSkleton";

export const RelationRequest = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { data, isLoading } = useGetRelationRequestQuery();
  const [value, setValue] = useState(0);
  let receivedRequestCount = 0;
  let sentRequestCount = 0;

  //-----------------------Declare Functions Here-----------------------------//

  const handleChange = (_, newValue) => {
    setValue(newValue);
  };
  const handleChangeIndex = (index) => {
    setValue(index);
  };
  const a11yProps = (index) => {
    return {
      id: `full-width-tab-${index}`,
      "aria-controls": `full-width-tabpanel-${index}`,
    };
  };
  data?.relReqList?.received.forEach((item) => {
    if (item.req_decision === "Requested") receivedRequestCount += 1;
  });
  data?.relReqList?.sent.forEach((item) => {
    if (item.req_decision === "Requested") sentRequestCount += 1;
  });
  return (
    <>
      <AppBar position="static">
        <Tabs
          value={value}
          onChange={handleChange}
          indicatorColor="secondary"
          textColor="inherit"
          aria-label="full width tabs example"
          centered={true}
          sx={{
            backgroundColor: "white.main",
            color: "#333333",
            borderTopLeftRadius: 1,
            borderTopRightRadius: 1,
          }}
        >
          <Tab
            icon={
              <Badge
                color="secondary"
                badgeContent={receivedRequestCount}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box sx={{ m: 0.8 }}>
                  {t("display.text.text.requestRecieved")}
                </Box>
              </Badge>
            }
            iconPosition="end"
            {...a11yProps(0)}
          />
          <Tab
            icon={
              <Badge
                color="secondary"
                badgeContent={sentRequestCount}
                anchorOrigin={{
                  vertical: "top",
                  horizontal: "right",
                }}
              >
                <Box sx={{ m: 0.8 }}>{t("display.text.text.requestSent")}</Box>
              </Badge>
            }
            iconPosition="end"
            {...a11yProps(1)}
          />
        </Tabs>
      </AppBar>
      <Divider></Divider>
      <SwipeableViews
        axis={theme.direction === "rtl" ? "x-reverse" : "x"}
        index={value}
        onChangeIndex={handleChangeIndex}
      >
        <TabPanel value={value} index={0} dir={theme.direction}>
          {!isLoading ? (
            <Card
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                p: 1,
                minHeight: "76vh",
                width: "100%",
              }}
            >
              <Stack spacing={1}>
                {data?.relReqList?.received?.length === 0 ? (
                  <Typography align="center">
                    {t("display.text.text.noRequestReceived")}
                  </Typography>
                ) : (
                  data?.relReqList?.received.map((item) => (
                    <RelationRequestReceivedCard {...item} />
                  ))
                )}
              </Stack>
            </Card>
          ) : (
            <RelationRequestSkleton />
          )}
        </TabPanel>
        <TabPanel value={value} index={1} dir={theme.direction}>
          {!isLoading ? (
            <Card
              sx={{
                borderTopLeftRadius: 0,
                borderTopRightRadius: 0,
                p: 1,
                minHeight: "76vh",
                width: "100%",
              }}
            >
              <Stack spacing={1}>
                {data?.relReqList?.sent?.length === 0 ? (
                  <Typography align="center">
                    {t("display.text.text.noRequestSent")}
                  </Typography>
                ) : (
                  data?.relReqList?.sent.map((item) => (
                    <RelationRequestSentCard {...item} />
                  ))
                )}
              </Stack>
            </Card>
          ) : (
            <RelationRequestSkleton />
          )}
        </TabPanel>
      </SwipeableViews>
    </>
  );
};
