import Stack from "@mui/material/Stack";
import Card from "@mui/material/Card";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";
import React from "react";
export const RelationRequestSkleton = () => {
  return (
    <Card sx={{ minHeight: "76vh", width: "100%", p: 1 }}>
      <Stack spacing={1}>
        {Array(4)
          .fill()
          .map((_, idx) => (
            <Card
              sx={{
                borderStyle: "solid",
                borderColor: "border.main",
                borderWidth: "thin",
                p: 0.5,
                width: "100%",
              }}
              key={idx}
            >
              <Stack spacing={1}>
                <Skeleton
                  variant="text"
                  width={"30%"}
                  sx={{ fontSize: "0.5rem" }}
                />
                <Divider></Divider>
                <Skeleton
                  variant="text"
                  width={"80%"}
                  sx={{ fontSize: "1.2rem" }}
                />
                <Skeleton
                  variant="text"
                  width={"30%"}
                  sx={{ fontSize: "0.8rem" }}
                />
              </Stack>
            </Card>
          ))}
      </Stack>
    </Card>
  );
};
