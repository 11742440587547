import { createSlice } from "@reduxjs/toolkit";
const initialState = {
  mode: null,
  relationship: null,
  relatedTo: null,
  currentPage: null,
};

export const createLgSlice = createSlice({
  name: "createLg",
  initialState,
  reducers: {
    updateMode: (state, action) => {
      state.mode = action.payload;
    },
    updateRelationship: (state, action) => {
      state.relationship = action.payload;
    },
    updateRelatedTo: (state, action) => {
      state.relatedTo = action.payload;
    },
    updateCurrentPage: (state, action) => {
      state.currentPage = action.payload;
    },
    resetCreateLg: () => {
      return initialState;
    },
  },
});

// Action creators are generated for each case reducer function
export const {
  resetCreateLg,
  updateMode,
  updateRelationship,
  updateRelatedTo,
  updateCurrentPage,
} = createLgSlice.actions;

export default createLgSlice.reducer;
