import { postApi } from "../utils/functions";

export const checkLgUrl = async (value) => {
  try {
    let responseJSON = await postApi("chkLgUrl", { lg_url: value });
    if (!responseJSON.error) {
      try {
        if (responseJSON.message === "url_uniq") {
          return true;
        }
        return false;
      } catch (e) {
        console.error(e);
      }
    }
  } catch (e) {
    console.error(e);
  }
};
