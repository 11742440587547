import React from "react";
import { DeleteEntryPromt } from "./DeleteEntryPromt";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import { t } from "i18next";

export const InfoPopup = ({
  open,
  close = () => {},
  createdBy = "- -",
  updatedBy = "- -",
  createdOn = "- -",
  updatedOn = "- -",
}) => {
  const headingStyles = {
    fontSize: "14px",
    fontWeight: 400,
    color: "text.grey",
  };
  const bodyStyles = { fontSize: "16px", fontWeight: 600, color: "#3F0D56" };

  return (
    <DeleteEntryPromt
      open={open}
      singleButtonOnClick={() => close()}
      type="info"
      buttonType="single"
      title={t("display.text.button.info")}
      message={
        <Stack direction={"row"} spacing={4} sx={{ minWidth: "250px", px: 1 }}>
          <Stack spacing={1.5}>
            <Box>
              <Typography sx={headingStyles}>Created By :</Typography>
              <Typography sx={bodyStyles}>{createdBy}</Typography>
            </Box>
            <Box>
              <Typography sx={headingStyles}>Last Updated By :</Typography>{" "}
              <Typography sx={bodyStyles}>{updatedBy}</Typography>
            </Box>
          </Stack>
          <Stack spacing={1.5}>
            <Box>
              <Typography sx={headingStyles}>Created On :</Typography>
              <Typography sx={bodyStyles}>{createdOn}</Typography>
            </Box>
            <Box>
              <Typography sx={headingStyles}>Last Updated On : </Typography>
              <Typography sx={bodyStyles}>{updatedOn}</Typography>
            </Box>
          </Stack>
        </Stack>
      }
    />
  );
};
