import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { findDateDifference } from "../../utils/functions";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { updateNotificationPromt } from "../../redux/slice/authentication";
// import { fetchToken, updateFcmToken } from "../../firebase";
import { DeleteEntryPromt } from "../atoms/DeleteEntryPromt";

export const NotificationPromt = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { notification } = useSelector((state) => state.authentication);
  const dispatch = useDispatch();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    // updateFcmToken();
    promt();
  }, [notification]);

  //-----------------------Declare Functions Here-----------------------------//

  const promt = () => {
    if (Notification.permission === "default") {
      if (notification.permission === "default") {
        if (notification.eligible) {
          if (!notification.lastPromt) {
            setOpen(true);
          } else if (findDateDifference(notification.lastPromt).days > 1) {
            setOpen(true);
          }
        }
      }
    }
  };

  const handleYes = () => {
    Notification.requestPermission().then((permission) => {
      if (permission === "granted") {
        dispatch(
          updateNotificationPromt({
            permission: "granted",
            lastPromt: new Date().toISOString(),
          })
        );
        // make api calls here
        // fetchToken();
      } else if (permission === "denied") {
        dispatch(
          updateNotificationPromt({
            lastPromt: new Date().toISOString(),
          })
        );
      }
    });
    setOpen(false);
  };
  const handleNo = () => {
    dispatch(updateNotificationPromt({ lastPromt: new Date().toISOString() }));
    setOpen(false);
  };

  return (
    <>
      <DeleteEntryPromt
        open={open}
        close={() => setOpen(false)}
        title={t("display.text.text.notification")}
        message={
          <Typography component={"div"}>
            {t("display.text.text.receiveNotification")}
          </Typography>
        }
        onCancel={handleNo}
        onDelete={handleYes}
      />
    </>
  );
};
