import { t } from "i18next";
import React, { useEffect, useState } from "react";
import "./index.css";
import { formatDate } from "../../utils/functions";
export const GreetingCard = ({
  baseURL = "",
  sticker_name = "",
  creator_name = "",
  wish_style = "",
  wish_desc = "",
  recieverName = "",
  created_date = "",
  flipValue = false,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [flip, setFlip] = useState(flipValue);
  useEffect(() => {
    setFlip(Boolean(flipValue));
  }, [flipValue]);
  return (
    <>
      <div style={{ display: "flex", width: "100%", justifyContent: "center" }}>
        <div
          className={flip ? "flip-card-hover" : "flip-card"}
          onClick={() => setFlip(!flip)}
        >
          <div className="flip-card-inner">
            <div
              className="flip-card-front"
              style={{
                backgroundImage: `url(${baseURL}/${
                  sticker_name.split(".")[0]
                }_front.${sticker_name.split(".")[1]})`,
              }}
            >
              {/* <div className="invert-title">
                <h4 className="color1">{t("display.text.text.wishFrom")}</h4>
                <h4 className="color2 margin">{creator_name}</h4>{" "}
              </div> */}
            </div>
            <div
              className="flip-card-back"
              style={{
                backgroundImage: `url(${baseURL}/${sticker_name})`,
              }}
            >
              <div className="flip-card-details" style={wish_style?.container}>
                <div style={{ textAlign: "center" }}>
                  <p className="text-left" style={wish_style?.header}>
                    {`${t("display.text.text.dear")} ${recieverName},`}
                  </p>
                  <div className="wish-message" style={wish_style?.body}>
                    {wish_desc}
                  </div>
                  <div style={wish_style?.footer}>
                    <div className="text-right">
                      {t("display.text.text.love")}
                    </div>
                    <div className="text-right">{creator_name}</div>
                    <div className="text-right">{formatDate(created_date)}</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
