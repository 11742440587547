import React from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import { t } from "i18next";
import Typography from "@mui/material/Typography";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import PersonIcon from "@mui/icons-material/Person";
import MenuBookIcon from "@mui/icons-material/MenuBook";
import { useSelector } from "react-redux";
import { OwnerCard } from "../atoms/OwnerCard";
import { findSelf } from "../../utils/functions";

export const SettingsPage = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  const { userStatus } = useSelector((state) => state.config);

  return (
    <Card sx={{ p: 1 }}>
      <Stack spacing={1}>
        <OwnerCard
          readURL={findSelf()?.readURL}
          name={`${findSelf()?.fname} ${findSelf()?.lname}`}
          status={userStatus}
        />
        <Card sx={{ backgroundColor: "background.dark" }}>
          <Button
            color="inherit"
            sx={{ width: "100%", justifyContent: "space-between" }}
            onClick={() => navigate("/settings/account")}
            endIcon={<NavigateNextIcon />}
          >
            <Stack spacing={1} direction={"row"}>
              <PersonIcon />
              <Typography>{t("display.text.text.accountSettings")}</Typography>
            </Stack>
          </Button>
          <Divider></Divider>
          {userStatus === "active" && (
            <Button
              color="inherit"
              sx={{ width: "100%", justifyContent: "space-between" }}
              onClick={() => navigate("/settings/lifograf")}
              endIcon={<NavigateNextIcon />}
            >
              <Stack spacing={1} direction={"row"}>
                <MenuBookIcon />
                <Typography>
                  {t("display.text.text.lifografSettings")}
                </Typography>
              </Stack>
            </Button>
          )}
        </Card>
      </Stack>
    </Card>
  );
};
