import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import React from "react";
import Stack from "@mui/material/Stack";
import Skeleton from "@mui/material/Skeleton";
import Divider from "@mui/material/Divider";

export const LifeEventSkleton = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  //-----------------------Declare function Here-----------------------------//

  return (
    <Card
      sx={{
        width: "100%",
        p: 0.5,
      }}
    >
      <Stack spacing={0.75}>
        <Grid
          container
          sx={{ height: { xs: "unset", sm: "unset", md: 160, lg: 160 } }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{
              height: { xs: "256px", sm: "256px", md: "100%", lg: "100%" },
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            <Skeleton variant="rounded" height={160} width={195} />
          </Grid>
          <Grid
            sx={{
              pl: { xs: 0, md: 1 },
              height: { xs: "fit-content", sm: "100%" },
              position: "relative",
            }}
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "91%" }}>
                  <Skeleton variant="text" />
                </Box>
              </Box>
              <Skeleton variant="text" width={60} />
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                <Box sx={{ width: "100%" }}>
                  <Skeleton variant="text" />
                  <Skeleton variant="text" />
                </Box>
              </Box>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "space-between",
                  alignItems: "center",
                  width: "100%",
                }}
              >
                <Box>
                  <Skeleton variant="text" width={25} />
                </Box>
                <Box sx={{ justifyContent: "flex-end" }}>
                  <Skeleton variant="text" width={25} />
                </Box>
              </Box>
            </Box>
          </Grid>
        </Grid>
        <>
          <Divider sx={{ width: "100%" }} />
          <Grid container sx={{ pb: 0.5 }}>
            <Grid
              item
              xs={6}
              sx={{
                display: "flex",
                justifyContent: "center",
                borderStyle: "solid",
                borderColor: "border.main",
                borderWidth: 0,
                borderRightWidth: "thin",
              }}
            >
              <Skeleton variant="text" width={"30%"} />
            </Grid>
            <Grid
              item
              xs={6}
              sx={{ display: "flex", justifyContent: "center" }}
            >
              <Skeleton variant="text" width={"30%"} />
            </Grid>
          </Grid>
        </>
      </Stack>
    </Card>
  );
};
