import Box from "@mui/material/Box";
import React, { useEffect, useState } from "react";
import { BusyPointer } from "../../UI/BusyPointer";
import { useStyles } from "../../utils/Theme";
export const FetchHtml = ({ link }) => {
  const [data, setData] = useState(false);
  const classes = useStyles();
  useEffect(() => {
    fetch(link)
      .then((response) => {
        return response.text();
      })
      .then((response) => {
        var parser = new DOMParser();
        var doc = parser.parseFromString(response, "text/html");
        setData(doc.body.innerHTML);
      });
  }, []);

  return (
    <Box
      className={classes.root}
      sx={{ height: "100%", width: "100%", overflowY: "scroll", pb: 1 }}
    >
      {data ? (
        <div
          style={{
            height: "100%",
            width: "100%",
            paddingBottom: "8px",
          }}
          dangerouslySetInnerHTML={{ __html: data }}
        ></div>
      ) : (
        <Box
          sx={{
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        >
          <BusyPointer />
        </Box>
      )}
    </Box>
  );
};
