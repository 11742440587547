import Dialog from "@mui/material/Dialog";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import React from "react";
import { assetSource, bondingBadge } from "../../utils/ListItems";

export const BondingBadgePopup = ({
  lg_score = 0,
  engagement_score = 0,
  open,
  close,
}) => {
  return (
    <>
      <Dialog open={open} onClose={close}>
        <Card
          sx={{
            backgroundColor: "transparent",
            background: `url(${assetSource}/img_profileBadgeCardBG)`,
            width: "250px",
            height: "300px",
            // backgroundRepeat: "no-repeat",
            backgroundSize: "contain",
          }}
        >
          <Stack spacing={1} sx={{ width: "100%" }}>
            <Box sx={{ display: "flex", justifyContent: "center" }}>
              <img
                style={{ width: "200px", height: "200px" }}
                src={bondingBadge["bonding"]}
              />
            </Box>
            <Typography
              sx={{ fontSize: "18px", fontWeight: 600, color: "#fff" }}
              align="center"
            >
              Bonding Beginer
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 400, color: "#fff" }}
              align="center"
            >
              {`Lifograf Score : ${lg_score}`}
            </Typography>
            <Typography
              sx={{ fontSize: "14px", fontWeight: 400, color: "#fff" }}
              align="center"
            >
              {`Engagement Score : ${engagement_score}`}
            </Typography>
          </Stack>
        </Card>
      </Dialog>
    </>
  );
};
