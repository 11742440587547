import React from "react";
import Snackbar from "@mui/material/Snackbar";
import MuiAlert from "@mui/material/Alert";
import { useTranslation } from "react-i18next";
import { resetAlert } from "../../redux/slice/alert";
import { useSelector } from "react-redux";
import { store } from "../../redux/store";

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const AlertMessage = () => {
  //-----------------------Declare Hooks Here-----------------------------//
  const { t } = useTranslation();
  const alert = useSelector((state) => state.alert);

  //-----------------------Declare Functions Here-----------------------------//
  const handleClose = () => {
    store.dispatch(resetAlert());
  };

  return (
    <>
      {alert.error && (
        <div>
          <Snackbar
            anchorOrigin={{ vertical: "top", horizontal: "center" }}
            open={alert.error}
            autoHideDuration={alert.severity === "error" ? null : 7000}
            onClose={() => {
              handleClose();
            }}
          >
            <Alert
              onClose={() => {
                handleClose();
              }}
              severity={alert.severity}
              sx={{ width: "100%" }}
            >
              {t(`display.text.error.${alert.message}`, { ...alert?.param })}
            </Alert>
          </Snackbar>
        </div>
      )}
    </>
  );
};

export default AlertMessage;
