import { setAlert } from "../redux/slice/alert";
import { store } from "../redux/store";
import { somethingWentWrong } from "../utils/constants";
import { postApi } from "../utils/functions";

export const addRelationApi = (formData) => {
  return postApi("relate", formData)
    .then((e) => {
      store.dispatch(setAlert(e));
      return e;
    })
    .catch((e) => {
      store.dispatch(setAlert(somethingWentWrong));
      return e;
    });
};
