export const regularExpressions = {
  email:
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  password: /^(?=.*[0-9])(?=.*[!@#$%^&*])[a-zA-Z0-9!@#$%^&*]{6,16}$/,
  youtube:
    // eslint-disable-next-line
    /^((?:https?:)?\/\/)?((?:www|m)\.)?((?:youtube(-nocookie)?\.com|youtu.be))(\/(?:[\w\-]+\?v=|embed\/|v\/)?)([\w\-]+)(\S+)?$/,
  url: /^((https?|ftp|smtp):\/\/)?(www.)?[a-z0-9]+\.[a-z]+(\/[a-zA-Z0-9#]+\/?)*$/,
  isYoutubeLink:
    /^(?:https?:\/\/)?(?:m\.|www\.)?(?:youtu\.be\/|youtube\.com\/(?:embed\/|v\/|watch\?v=|watch\?.+&v=))((\w|-){11})(?:\S+)?$/,
  lgUrl: /^\w+$/,
};

export const baseURL = "https://famscape.com/lg_api";
export const api = {
  lifeEventSticker: "https://stor1.famscape.com/openstore/lestickers",
};
export const OS = {
  mac: "Mac OS",
  ios: "iOS",
  win: "Windows",
  android: "Android",
  lin: "Linux",
};
export const somethingWentWrong = {
  error: true,
  severity: "error",
  message: "somethingWentWrong",
};
export const lifeEventSticker = [
  "accident_152075_640.png",
  "accident_297169.svg",
  "accident_3058590_640.jpg",
  "accident_40000.svg",
  "accident_89068_640.jpg",
  "bereaved_1207568_640.jpg",
  "bereaved_6787277_640.jpg",
  "bereaved_7420328_640.jpg",
  "bereaved_7607979_640.jpg",
  "birth_1681181_640.jpg",
  "birth_7653123_640.jpg",
  "birth_7780328_640.png",
  "careerchange_2105406_640.jpg",
  "careerchange_5513581_640.jpg",
  "careerchange_7400065_640.png",
  "careerchange_948024_640.jpg",
  "careerdown_1899227_640.jpg",
  "careerdown_3845245_640.png",
  "careerdown_5222697_640.png",
  "careerdown_5258605_640.png",
  "careerup_1103722_640.jpg",
  "careerup_1340649_640.jpg",
  "careerup_1871366_640.jpg",
  "careerup_3386334_640.jpg",
  "celebrate_1786430_640.jpg",
  "celebrate_1953253_640.jpg",
  "celebrate_2578446_640.jpg",
  "celebrate_309155.svg",
  "celebrity_1314734_640.jpg",
  "celebrity_2527495_640.jpg",
  "childbirth_2416718.jpg",
  "college_105709_640.jpg",
  "college_2052868_640.jpg",
  "college_4158244_640.jpg",
  "college_5473769_640.png",
  "crush_1046658_640.jpg",
  "crush_1261895_640.png",
  "crush_4779562_640.jpg",
  "divorce_1739128_640.jpg",
  "divorce_5064694_640.jpg",
  "divorce_6581213_640.jpg",
  "divorce_908743_640.png",
  "emergency_1010903_640.jpg",
  "emergency_24405.svg",
  "emergency_294378.svg",
  "emergency_4510408_640.png",
  "engaged_1721592_640.jpg",
  "engaged_2942081_640.jpg",
  "engaged_3611277_640.jpg",
  "engaged_36256_640.png",
  "friend_113746_640.png",
  "friend_3012515_640.jpg",
  "friend_329329_640.jpg",
  "friend_7008170_640.png",
  "friend_7448942_640.jpg",
  "gain_2889046_640.jpg",
  "gain_3581678_640.jpg",
  "gain_4399769_640.jpg",
  "gain_5067419_640.jpg",
  "graduate_1345123_640.png",
  "graduate_150373_640.png",
  "graduate_2841867_640.jpg",
  "graduate_37496.svg",
  "grief_1428080_640.png",
  "grief_4250450.svg",
  "grief_48522.svg",
  "grief_7356696_640.jpg",
  "hobby_163791_640.jpg",
  "hobby_3584204_640.jpg",
  "hobby_8026824_640.jpg",
  "house_163526_640.jpg",
  "house_1836070_640.jpg",
  "house_2323278_640.jpg",
  "house_846051_640.jpg",
  "IGNORE-childbirth_1839564_640.jpg",
  "job_1459246_640.png",
  "job_3599406.svg",
  "job_4247424.svg",
  "job_5266708_640.jpg",
  "joinschool_1445053_640.jpg",
  "joinschool_295210_640.png",
  "joinschool_3518726_640.jpg",
  "joinschool_5599231_640.png",
  "learnskill_1976725_640.jpg",
  "learnskill_2578353_640.jpg",
  "learnskill_2706977_640.jpg",
  "learnskill_415341_640.jpg",
  "legal_2025788.svg",
  "legal_5665992_640.jpg",
  "legal_6570152.svg",
  "loss_215512_640.jpg",
  "loss_3078562_640.jpg",
  "loss_5418100_640.jpg",
  "loss_6856469.svg",
  "love_1536226_640.jpg",
  "love_2026171.svg",
  "love_3061483_640.jpg",
  "love_560783_640.jpg",
  "mentor_2062999_640.png",
  "mentor_3610255_640.jpg",
  "parent_1082915_640.jpg",
  "parent_1781586.svg",
  "parent_2746549.svg",
  "parent_3120717_640.jpg",
  "parent_7675987_640.jpg",
  "pet_1532627.svg",
  "pet_160601.svg",
  "pet_468228_640.jpg",
  "pet_5781057_640.jpg",
  "pregnancy_1299514_640.png",
  "pregnancy_2047596_640.jpg",
  "pregnancy_2700659_640.jpg",
  "relocation_7074105.svg",
  "relocation_1182620_640.jpg",
  "relocation_297219.svg",
  "relocation_668616_640.jpg",
  "retire_1979674_640.jpg",
  "retire_4959355_640.png",
  "retire_97986_640.jpg",
  "sickness_1884775_640.jpg",
  "sickness_2082630_640.jpg",
  "sickness_5308969_640.jpg",
  "sickness_867855_640.jpg",
  "soldpropd_3180037_640.jpg",
  "soldprop_4101306_640.jpg",
  "soldprop_4326632_640.jpg",
  "soldprop_987096_640.jpg",
  "sport_1019776_640.jpg",
  "sport_1381230_640.jpg",
  "sport_157930_640.png",
  "sport_2343558_640.jpg",
  "turningpt_4719276_640.jpg",
  "turningpt_7400060_640.jpg",
  "turningpt_945272_640.png",
  "vehicle_150155.svg",
  "vehicle_1921700_640.jpg",
  "vehicle_40241.svg",
  "vehicle_42552.svg",
  "volunteer_1888823_640.png",
  "volunteer_2349725_640.jpg",
  "volunteer_4330453_640.jpg",
  "wedding_1093132_640.jpg",
  "wedding_160866_640.png",
  "wedding_1846114_640.jpg",
  "wedding_1984047_640.png",
  "win_1010916_640.jpg",
  "win_1674911_640.png",
  "win_2829615_640.png",
  "win_2889040_640.jpg",
  "wisdom_4153292_640.jpg",
  "wisdom_6230151.svg",
  "wisdom_708683_640.jpg",
];
export const profileReactions = [
  "birdsOfFeather",
  "realHero",
  "pillarOfStrength",
  "heartOfGold",
  "gemOfAPerson",
  "guidingLights",
  "loveIt",
  "respect",
  "fireCracker",
  "connected",
  "admiration",
  "sunShine",
  "guardianAngel",
  "seekBlessing",
  "blessYou",
];
export const lifeEventReactions = [
  "anguished",
  "surprised",
  "brokenHeart",
  "celebrate",
  "cry",
  // "delighted",
  "happy",
  "takeCare",
];
export const momentsReactions = [
  "treasure2",
  "nostalgia",
  "goldenTimes",
  "loveIt",
  "cuddlyCute",
  "timeless",
];
export const achievementReactions = [
  "claps",
  "highVoltage",
  "winners",
  "onFire",
  "thumbsUp",
  "perfect",
];
export const wisdomReactions = [
  "hatsOff",
  "stars",
  "loveIt",
  "sage",
  "treasure",
  "thumbsUp",
  "thankYou",
];
export const anecdotesReaction = [
  "surprised",
  "nostalgia",
  "rofl",
  "sad",
  "speechless",
  "touching",
];
export const announcementReactions = [
  "hi5",
  "praying",
  "goodLuck",
  "wishSuccess",
  "cool",
  "blessYou",
  "party2",
  "cheering",
  "hugs",
];
export const wishReactions = ["loveIt", "thankYou", "hugs"];

export const personalDefaultValues = {
  dob: null,
  birth_time: null,
  dod: null,
  nat_lang: null,
  religion: null,
  birth_cntry: null,
  anc_region: null,
  nation: null,
  last_res: null,
  dth_loc: null,
  death_cause: null,
  race: null,
  caste: null,
  clan: null,
  star: null,
  pers_add_details: null,
  mort_rem: null,
  mort_rem_loc: null,
  pers_visib: "dist",
};
