import React, { useState } from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import Button from "@mui/material/Button";
import { activateLifografApi } from "../../api/activateLifografApi";
import { DeleteEntryPromt } from "../atoms/DeleteEntryPromt";
import { t } from "i18next";

export const ActivateLifograf = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [yes, setYes] = useState(false);
  const [no, setNo] = useState(false);

  //-----------------------Declare Function Here-----------------------------//
  const handleClose = () => {
    setYes(false);
    setNo(false);
  };
  const handleDecesion = (value) => {
    activateLifografApi({ accept_lg: value });
  };
  return (
    <>
      <Card
        sx={{
          p: 1,
          width: "100%",
          borderStyle: "solid",
          borderColor: "border.yellow",
          borderWidth: "thin",
          backgroundColor: "background.yellow",
        }}
      >
        <Stack spacing={1}>
          <Typography>
            {t("display.text.text.activateLifografHeading")}
          </Typography>
          <Divider></Divider>
          <Typography>{t("display.text.text.activateLifografBody")}</Typography>
          <Divider></Divider>
          <Stack
            spacing={3}
            direction={"row"}
            sx={{ display: "flex", justifyContent: "flex-end" }}
          >
            <Button onClick={() => setNo(true)}>
              {" "}
              {t("display.text.button.rejectLifograf")}
            </Button>
            <Button variant="contained" onClick={() => setYes(true)}>
              {t("display.text.button.activateLifograf")}
            </Button>
          </Stack>
        </Stack>
      </Card>
      <DeleteEntryPromt
        open={no}
        close={handleClose}
        onDelete={() => handleDecesion("no")}
        onCancel={handleClose}
        title={t("display.text.text.areYouSureRejectFamily")}
        message={t("display.text.text.rejectActivateLifografMessage")}
        type={"error"}
      />
      <DeleteEntryPromt
        open={yes}
        close={handleClose}
        onDelete={() => handleDecesion("yes")}
        onCancel={handleClose}
        title={t("display.text.text.areYouSure")}
        message={t("display.text.text.ActivateLifografMessage")}
        type={"success"}
      />
    </>
  );
};
