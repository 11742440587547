import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import Dialog from "@mui/material/Dialog";
import Avatar from "@mui/material/Avatar";
import { useLocation, useNavigate } from "react-router-dom";

const CustomAvatar = ({
  src,
  lgId = null,
  sx = { height: "45px", width: "45px", padding: "4px" },
  decoration,
  key = 1,
  loading = false,
  preview = false,
  sx1 = {},
  customStyles = false,
}) => {
  const padding = sx.padding ? sx.padding : "4px";
  const navigate = useNavigate();
  const location = useLocation();
  const [open, setOpen] = useState(false);
  useEffect(() => {
    if (open) {
      navigate(location.pathname);
      window.addEventListener("popstate", () => {
        setOpen(false);
      });
    }
    return window.removeEventListener("popstate", () => {
      setOpen(false);
    });
    // eslint-disable-next-line
  }, [open]);
  const avatarStyles = customStyles
    ? sx1
    : {
        width: `${parseInt(sx.width, 10) - parseInt(padding, 10)}px`,
        height: `${parseInt(sx.height, 10) - parseInt(padding, 10)}px`,
      };
  return (
    <>
      <Box
        sx={{
          position: "relative",
          ...sx,
          "& :hover": { cursor: preview ? "pointer" : "arrow" },
        }}
        onClick={() => {
          if (preview) setOpen(true);
        }}
        key={key}
      >
        {src && decoration && !loading && (
          <img
            src={decoration ? decoration : ""}
            style={{
              position: "absolute",
              width: "100%",
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
              zIndex: 1,
            }}
            alt="mising decoration"
            key={src}
          />
        )}
        <Box
          sx={{
            ...sx1,
            "& .MuiAvatar-circular .MuiAvatar-fallback": sx1,
          }}
        >
          {<Avatar src={loading ? "" : src} sx={avatarStyles} key={src} />}
        </Box>
      </Box>
      <Dialog fullWidth open={open} onClose={() => setOpen(false)}>
        <img
          style={{ width: "100%", height: "100%" }}
          src={src}
          alt="missing avatar"
        />
      </Dialog>
    </>
  );
};

export default CustomAvatar;
