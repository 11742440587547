import React from "react";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Divider from "@mui/material/Divider";
import Box from "@mui/material/Box";
import Skeleton from "@mui/material/Skeleton";

export const LifografSettingPageSkleton = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  //-----------------------Declare Function Here-----------------------------//

  return (
    <>
      <Card sx={{ p: 1 }}>
        <Card sx={{ width: "100%", p: 1 }}>
          <Stack spacing={1}>
            <Box
              sx={{ width: "100%", display: "flex", justifyContent: "center" }}
            >
              <Stack spacing={1}>
                <Skeleton variant="circular" width={80} height={80} />
                <Skeleton variant="text" sx={{ fontSize: "0.75rem" }} />
                <Skeleton variant="text" sx={{ fontSize: "0.75rem" }} />
              </Stack>
            </Box>
            <Divider></Divider>
            <Stack spacing={1}>
              <Skeleton
                variant="text"
                sx={{ fontSize: "2rem" }}
                width={"40%"}
              />
              <Stack spacing={0.5}>
                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={"30%"}
                />

                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={"30%"}
                />

                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={"30%"}
                />

                <Skeleton
                  variant="text"
                  sx={{ fontSize: "1rem" }}
                  width={"30%"}
                />
              </Stack>
              <Divider></Divider>
              <Skeleton
                variant="text"
                sx={{ fontSize: "2rem" }}
                width={"40%"}
              />
              <Stack spacing={0.5}>
                {Array(5)
                  .fill()
                  .map((_, idx) => {
                    return (
                      <Card
                        key={idx}
                        sx={{ backgroundColor: "background.dark", p: 1 }}
                      >
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem" }}
                          width={"30%"}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem" }}
                          width={"30%"}
                        />
                        <Skeleton
                          variant="text"
                          sx={{ fontSize: "1rem" }}
                          width={"30%"}
                        />
                      </Card>
                    );
                  })}
              </Stack>
              <Divider></Divider>
              <Skeleton
                variant="text"
                sx={{ fontSize: "2rem" }}
                width={"40%"}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem" }}
                width={"30%"}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem" }}
                width={"30%"}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem" }}
                width={"30%"}
              />
              <Divider></Divider>
              <Skeleton
                variant="text"
                sx={{ fontSize: "2rem" }}
                width={"40%"}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem" }}
                width={"30%"}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem" }}
                width={"30%"}
              />
              <Divider></Divider>
              <Skeleton
                variant="text"
                sx={{ fontSize: "2rem" }}
                width={"40%"}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem" }}
                width={"30%"}
              />
              <Skeleton
                variant="text"
                sx={{ fontSize: "1rem" }}
                width={"30%"}
              />
            </Stack>
          </Stack>
        </Card>
      </Card>
    </>
  );
};
