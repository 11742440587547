// src/services/apiSlice.js
import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react";
import { baseURL } from "../../utils/constants";
import { prepareHeaders } from "../../utils/functions";

export const mainApi = createApi({
  reducerPath: "mainApi", // Optional but recommended
  baseQuery: fetchBaseQuery({
    baseUrl: `${baseURL}`,
    prepareHeaders: prepareHeaders,
  }),
  endpoints: () => ({}),
});
