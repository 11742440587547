import React from "react";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Box from "@mui/material/Box";
import { useNavigate } from "react-router-dom";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import { useSelector } from "react-redux";
import { formatRelationship } from "../../utils/functions";
import { UserNameCard } from "../atoms/UserNameCard";

export const LifografSettings = () => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  const { ownedLg } = useSelector((state) => state.userSummary);

  return (
    <Card sx={{ p: 1 }}>
      <Stack spacing={1}>
        <Card sx={{ backgroundColor: "background.dark" }}>
          {ownedLg
            .filter(
              (item) =>
                item.owner_type === "Self" || item.owner_type === "Owner"
            )
            .map((item, idx) => (
              <Box key={idx}>
                <Button
                  color="inherit"
                  sx={{ width: "100%", justifyContent: "space-between" }}
                  onClick={() => navigate(`/settings/lifograf/${item.lg_id}`)}
                  endIcon={<NavigateNextIcon />}
                >
                  <Box sx={{ width: "100%" }}>
                    <UserNameCard
                      src={item.readURL}
                      name={`${item.fname} ${item.lname}`}
                      relation={formatRelationship(item.lg_id)}
                      ownerType={item.owner_type}
                    />
                  </Box>
                </Button>
                <Divider></Divider>
              </Box>
            ))}
        </Card>
      </Stack>
    </Card>
  );
};
