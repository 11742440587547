import Stack from "@mui/material/Stack";
import React from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import Typography from "@mui/material/Typography";
import Box from "@mui/material/Box";
import Badge from "@mui/material/Badge";
import Tooltip from "@mui/material/Tooltip";
import {
  capitalizeFirstLetter,
  chooseOwnerTypeColor,
  chooseStatusColor,
} from "../../utils/functions";
import { t } from "i18next";

export const OwnerCard = ({
  readURL,
  name,
  status,
  relation,
  ownerType,
  lgScore,
  engagementScore,
}) => {
  return (
    <Stack
      spacing={1}
      sx={{
        width: "100%",
      }}
    >
      <Box
        sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      >
        <CustomAvatar src={readURL} sx={{ height: "85px", width: "85px" }} />
      </Box>
      <Typography
        sx={{
          width: "100%",
          overflow: "hidden",
          textOverflow: "ellipsis",
          textWrap: "nowrap",
        }}
        align="center"
        variant="h6"
      >
        {name}
      </Typography>
      {relation && (
        <Typography
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textWrap: "nowrap",
          }}
          align="center"
          variant="caption"
          component={"div"}
        >
          {relation}
          {ownerType && (
            <Tooltip title={ownerType}>
              <Badge
                sx={{
                  ml: 1,
                  ".MuiBadge-dot": {
                    backgroundColor: chooseOwnerTypeColor(ownerType),
                    borderRadius: "0px",
                    height: "12px",
                    minWidth: "4px",
                  },
                }}
                variant="dot"
              />
            </Tooltip>
          )}
        </Typography>
      )}{" "}
      {status && (
        <Typography
          sx={{
            width: "100%",
            overflow: "hidden",
            textOverflow: "ellipsis",
            textWrap: "nowrap",
          }}
          variant="caption"
          align="center"
        >
          {t("display.text.text.userStatus", {
            param1: capitalizeFirstLetter(status),
          })}
          <Badge
            sx={{
              ml: 1,
              ".MuiBadge-dot": {
                backgroundColor: chooseStatusColor(status),
                borderRadius: "0px",
                height: "12px",
                minWidth: "4px",
              },
            }}
            variant="dot"
          />
        </Typography>
      )}
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        {lgScore && (
          <Typography variant="caption">
            {t("display.text.text.lgScore1", { param1: lgScore })}
          </Typography>
        )}
        {engagementScore && (
          <Typography variant="caption">
            {t("display.text.text.engagementScore1", {
              param1: engagementScore,
            })}
          </Typography>
        )}
      </Box>
    </Stack>
  );
};
