import React from "react";
import { ProfileCard } from "./ProfileCard";
import { findOwner, formatRelationship } from "../../utils/functions";
import Card from "@mui/material/Card";
import { ProfileCover } from "./ProfileCover";
import { ProfileCardSkleton } from "../Skleton/ProfileCardSkleton";
export const ProfileHeader = ({ loading, lgId, data }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  let lg = data?.output;
  return (
    <Card>
      {!loading ? (
        <>
          <ProfileCover
            lg_id={lg?.lg_id}
            fstr={lg?.fstr}
            type={lg?.banner_file_type}
            coverPicKey={lg?.coverPicKey}
          />
          <ProfileCard
            src={lg?.profPicReg}
            name={`${lg?.first_name} ${lg?.last_name}`}
            relation={formatRelationship(lg?.lg_id)}
            ownerType={findOwner(lg?.lg_id)?.owner_type}
            desc={lg?.short_desc}
            reaction={lg?.prof_reaction_summary}
            lg_id={lg?.lg_id}
            url={lg?.lg_url}
            fstr={lg?.fstr}
            lg_score={lg?.lg_score}
            engagement_score={lg?.engagement_score}
          />
        </>
      ) : (
        <ProfileCardSkleton />
      )}
    </Card>
  );
};
