import React from "react";
import { useGetCommentQuery } from "../../redux/slice/comment";
import { CommentPill } from "../atoms/CommentPill";
export const ViewComments = ({
  lg_id,
  entry_id,
  entry_type,
  user_connect_to_lg,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const { data, error, isLoading } = useGetCommentQuery({
    lg_id: lg_id,
    entry_id: entry_id,
    entry_type: entry_type,
  });
  return (
    data &&
    data.commentList.map((item, idx) => (
      <CommentPill
        key={idx}
        {...item}
        user_connect_to_lg={user_connect_to_lg}
        entry_id={entry_id}
        entry_type={entry_type}
        lg_id={lg_id}
      />
    ))
  );
};
