import React, { useEffect, useState } from "react";
import Drawer from "@mui/material/Drawer";
import MenuIcon from "@mui/icons-material/Menu";
import IconButton from "@mui/material/IconButton";
import { useLocation, useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import { OS } from "../../utils/constants";

export default function AppDrawer({ anchor, icon = <MenuIcon />, children }) {
  //-----------------------Declare Hooks Here-----------------------------//

  const [state, setState] = useState(false);
  const { userAgent } = useSelector((state) => state.config);
  const navigate = useNavigate();
  const location = useLocation();
  useEffect(() => {
    if (state && userAgent === OS.android) {
      navigate(location.pathname);
      window.addEventListener("popstate", () => {
        setState(false);
      });
    }
    return window.removeEventListener("popstate", () => {
      setState(false);
    });
    // eslint-disable-next-line
  }, [state]);

  //-----------------------Declare functions Here-----------------------------//

  const toggleDrawer = () => (event) => {
    if (
      event.type === "keydown" &&
      (event.key === "Tab" || event.key === "Shift")
    ) {
      return;
    }

    setState(!state);
  };

  return (
    <>
      <IconButton
        sx={{ height: "fit-content", "&:hover": { backgroundColor: "unset" } }}
        onClick={toggleDrawer()}
      >
        {icon}
      </IconButton>
      <Drawer
        sx={{
          ".MuiBackdrop-root": { backgroundColor: "transparent" },
          ".MuiDrawer-paper": {
            width: { xs: "70vw", sm: "50vw", md: "35vw", lg: "20vw" },
            borderStyle: "solid",
            borderColor: "border.main",
            borderWidth: 0,
            [`border${anchor === "left" ? "Right" : "Left"}Width`]: "thin",
          },
        }}
        anchor={anchor}
        open={state}
        onClose={toggleDrawer()}
      >
        {React.cloneElement(children, {
          toggle: () => setState(!state),
        })}
      </Drawer>
    </>
  );
}
