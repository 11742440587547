import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import React from "react";
import { Stack } from "@mui/material";
import Skeleton from "@mui/material/Skeleton";

export const ProfileCardSkleton = () => {
  return (
    <div className="profile-card-skleton">
      <Skeleton height={150} width={"100%"} variant="text" />
      <Box sx={{ width: "100%", p: 1, position: "relative" }}>
        <Stack spacing={1}>
          <Stack
            direction={"row"}
            spacing={1}
            sx={{ display: "flex", alignItems: "center" }}
          >
            <Box>
              <Skeleton variant="circular" height={150} width={150} />
            </Box>
            <Box sx={{ width: "100%" }}>
              <Stack spacing={1}>
                <Skeleton sx={{ fontSize: "1.5rem" }} width={"35%"} />
                <Skeleton sx={{ fontSize: "0.5rem" }} width={"10%"} />
              </Stack>
            </Box>
          </Stack>
          <Divider></Divider>
          <Box
            sx={{ display: "flex", justifyContent: "center", width: "100%" }}
          >
            <Skeleton sx={{ fontSize: "1rem" }} width={"25%"} />
          </Box>
        </Stack>
      </Box>
    </div>
  );
};
