import React, { useEffect, useState } from "react";
import Tree from "react-d3-tree";
import { useCenteredTree } from "../../utils/hooks";
import { FamilyTreeNode } from "../molecules/FamilyTreeNode";
import { useLazyGetFamilyTreeQuery } from "../../redux/slice/explore";
import {
  createFamTreeJson,
  findChildrenLength,
  getDynamicPathClass,
} from "../../utils/functions";
export const FamilyTree = ({ lg_id }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [dimensions, translate, containerRef] = useCenteredTree();
  const [treeJson, setTreeJson] = useState();
  const [apiCall, { data }] = useLazyGetFamilyTreeQuery();
  useEffect(() => {
    getFamilyTree(lg_id);
  }, []);
  //-----------------------Declare Function Here-----------------------------//

  const getFamilyTree = (lg_id) => {
    setTreeJson(false);
    apiCall(lg_id, true).then((response) => {
      setTreeJson(createFamTreeJson(response?.data?.famTreeList, "I"));
    });
  };
  const changeAnchor = (anchor) => {
    setTreeJson(false);
    setTimeout(
      () => setTreeJson(createFamTreeJson(data.famTreeList, anchor)),
      100
    );
  };

  return (
    <div style={{ height: "100%", width: "100%" }} ref={containerRef}>
      {treeJson && (
        <Tree
          data={treeJson.tree}
          orientation="vertical"
          pathFunc={"step"}
          depthFactor={200}
          centeringTransitionDuration={800}
          dimensions={dimensions}
          translate={translate}
          pathClassFunc={getDynamicPathClass}
          separation={{ nonSiblings: 1, siblings: 1.5 }}
          zoom={findChildrenLength(treeJson.tree)}
          renderCustomNodeElement={(e) => (
            <FamilyTreeNode
              e={e}
              rootAncestor={treeJson.root_ancestor}
              updateAnchor={changeAnchor}
              apiCall={getFamilyTree}
            />
          )}
          enableLegacyTransitions={true}
        />
      )}
    </div>
  );
};
