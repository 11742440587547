import Card from "@mui/material/Card";
import Typography from "@mui/material/Typography";
import React from "react";
import CustomAvatar from "../../UI/CustomAvathar";
import { findOwner } from "../../utils/functions";
import { t } from "i18next";
import Box from "@mui/material/Box";
import Divider from "@mui/material/Divider";
import { useNavigate } from "react-router-dom";

export const RelationRequestSentCard = ({
  base_relation,
  decider_name,
  decider_url,
  decision_date,
  fstr,
  lg_id,
  lg_id_name,
  lg_id_url,
  objKey,
  readURL,
  related_lg_id,
  req_decision,
  request_direction = "",
  requested_date,
  requestor_name,
  requestor_url,
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const owner = findOwner(related_lg_id);
  const navigate = useNavigate();
  return (
    <>
      <Card
        sx={{
          borderStyle: "solid",
          borderColor: "border.main",
          borderWidth: "thin",
          p: 0.5,
        }}
      >
        <Box
          sx={{
            display: "flex",
            flexWrap: "wrap",
            cursor: "pointer",
            gap: 0.5,
          }}
        >
          <Typography variant="caption">
            {t("display.text.text.requestedBy")}
          </Typography>
          <Typography variant="caption">{requestor_name}</Typography>
        </Box>
        <Divider></Divider>
        <Box
          //   direction={"row"}
          //   spacing={1}
          sx={{
            display: "inline-flex",
            alignItems: "center",
            flexWrap: "wrap",
            gap: 0.5,
            width: "fit-content",
          }}
        >
          <Typography
            sx={{
              fontWeight: "bold",
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            component={"div"}
            onClick={() =>
              navigate(`/profile/${lg_id_url.split("/")[4]}/about`)
            }
          >
            <Box sx={{ pt: 0.5 }}>
              <CustomAvatar
                sx={{ height: "20px", width: "20px" }}
                src={readURL}
              />
            </Box>
            {lg_id_name}
          </Typography>
          <Typography>
            {" "}
            {request_direction.toLowerCase() === "recieved"
              ? owner.owner_type === "Self"
                ? `${t("display.text.text.areThe")} ${base_relation} ${t(
                    "display.text.text.of"
                  )}`
                : `${t("display.text.text.isThe")} ${base_relation} ${t(
                    "display.text.text.of"
                  )}`
              : `${t("display.text.text.isThe")} ${base_relation} ${t(
                  "display.text.text.of"
                )}`}
          </Typography>
          <Typography
            sx={{
              fontWeight: "bold",
              display: "inline-flex",
              alignItems: "center",
              cursor: "pointer",
            }}
            component={"div"}
            onClick={() => navigate(`/profile/${owner?.lg_url}/about`)}
          >
            <Box sx={{ pt: 0.5 }}>
              <CustomAvatar
                sx={{ height: "20px", width: "20px" }}
                src={owner?.readURL}
              />
            </Box>
            <Box>{`${owner.fname} ${owner.lname}`}</Box>
          </Typography>
        </Box>
        <Box>
          {req_decision === "Approved" && (
            <Typography variant="caption" sx={{ color: "green" }}>
              {t("display.text.text.approvedBy")} {decider_name}
            </Typography>
          )}
          {req_decision === "Rejected" && (
            <Typography variant="caption" sx={{ color: "red" }}>
              {t("display.text.text.rejectedBy")} {decider_name}
            </Typography>
          )}
          {req_decision === "Requested" && (
            <Typography variant="caption" sx={{ color: "orange" }}>
              {t("display.text.text.pending")} {decider_name}
            </Typography>
          )}
        </Box>
      </Card>
    </>
  );
};
