import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  ownedLg: [],
  trending: [],
  blockedLg: [],
  relations: [],
  allrel: "",
  allrelrev: "",
  relcode: "",
  relrevcode: "",
  userPlan: "",
};
export const userSummary = createSlice({
  name: "userSummarry",
  initialState,
  reducers: {
    updateOwnedLg: (state, action) => {
      state.ownedLg = action.payload;
    },
    updateTrending: (state, action) => {
      state.trending = action.payload;
    },
    updateBlockedLg: (state, action) => {
      state.blockedLg = action.payload;
    },
    updateRelations: (state, action) => {
      state.relations = action.payload;
    },
    updateAllrel: (state, action) => {
      state.allrel = action.payload;
    },
    updateAllrelrev: (state, action) => {
      state.allrelrev = action.payload;
    },
    updateRelcode: (state, action) => {
      state.relcode = action.payload;
    },
    updateRelrevcode: (state, action) => {
      state.relrevcode = action.payload;
    },
    updateUserPlan: (state, action) => {
      state.userPlan = action.payload;
    },
    resetUserSummary: () => initialState,
  },
});

export const {
  updateOwnedLg,
  updateTrending,
  updateBlockedLg,
  updateRelations,
  updateAllrel,
  updateAllrelrev,
  updateRelcode,
  updateRelrevcode,
  updateUserPlan,
  resetUserSummary,
} = userSummary.actions;
export default userSummary.reducer;
