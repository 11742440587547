import Button from "@mui/material/Button";
import PersonAddIcon from "@mui/icons-material/PersonAdd";
import React, { useState } from "react";
import { t } from "i18next";
import { ChooseRelationship } from "./ChooseRelationship";
import { addRelationApi } from "../../api/addRelationApi";
export const AddRelation = ({
  lg_id = "",
  spouse = false,
  gender = "",
  name = "",
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const [open, setOpen] = useState(false);
  const [relatedTo, setRelatedTo] = useState(null);
  const [relationship, setRelationship] = useState(spouse ? gender : null);

  //-----------------------Declare Function Here-----------------------------//

  const handleAddRelation = () => {
    addRelationApi({
      lg_id: relatedTo.lg_id,
      base_lg_id: lg_id,
      base_relation: relationship,
    });
    handleClose();
  };

  const handleClose = (value) => {
    if (value === "next") {
      handleAddRelation();
    } else {
      setOpen(false);
      setRelatedTo(null);
    }
    // setRelationship(null);
  };
  return (
    <>
      <Button
        startIcon={<PersonAddIcon />}
        sx={{
          backgroundColor: "rgba(25, 118, 210, 0.04)",
          px: "4px",
          py: 0,
        }}
        onClick={() => setOpen(true)}
      >
        {t(
          spouse
            ? "display.text.button.addSpouse"
            : "display.text.button.addRelation"
        )}
      </Button>
      <ChooseRelationship
        open={open}
        setRelationship={(e) => {
          setRelationship(e);
        }}
        setRelatedTo={(e) => {
          setRelatedTo(e);
        }}
        relatedTo={relatedTo}
        relationship={relationship}
        close={(e) => handleClose(e)}
        disable={spouse ? "relationship" : ""}
        name={name}
        type={spouse ? "spouse" : "request"}
        mode={"add"}
      />
    </>
  );
};
