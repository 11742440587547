import Stack from "@mui/material/Stack";
import Chip from "@mui/material/Chip";
import Avatar from "@mui/material/Avatar";
import Autocomplete from "@mui/material/Autocomplete";
import Box from "@mui/material/Box";
import TextField from "@mui/material/TextField";
import CloseIcon from "@mui/icons-material/Close";
import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { findWho, isSelf } from "../../utils/functions";
import { t } from "i18next";
import { DeleteEntryPromt } from "./DeleteEntryPromt";
import {
  useAddBlockMutation,
  useAddOwnerMutation,
  useRemoveBlockMutation,
  useRemoveOwnerMutation,
} from "../../redux/slice/lifografSettings";
import LoadingButton from "@mui/lab/LoadingButton";

export const AddRemoveCollabOwner = ({
  type,
  lg_id,
  value = [],
  possibleValue = [],
}) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const navigate = useNavigate();
  const [deleteUser, setDelete] = useState(false);
  const [addSelected, setAddSelected] = useState(false);
  const [addOwner, { isLoading: addOwnerLoading }] = useAddOwnerMutation();
  const [blockUser, { isLoading: addBlockLoading }] = useAddBlockMutation();
  const [removeOwner] = useRemoveOwnerMutation();
  const [removeBlock] = useRemoveBlockMutation();
  const self = isSelf(lg_id);

  //-----------------------Declare Hooks Here-----------------------------//

  const onRemove = () => {
    if (type === "colloborators" || type === "owners") {
      const formData = { lg_id: lg_id, owner_lg_id: deleteUser.userId };
      removeOwner(formData)
        .then(() => setDelete(false))
        .catch(() => setDelete(false));
    } else {
      const formData = { lg_id: lg_id, blocked_lg_id: deleteUser.userId };
      removeBlock(formData)
        .then(() => setDelete(false))
        .catch(() => setDelete(false));
    }
  };
  const onAdd = async () => {
    if (type === "owners") {
      const formData = {
        lg_id: lg_id,
        owner_lg_id: addSelected,
        owner_type: "Owner",
      };
      addOwner(formData)
        .then(() => setAddSelected(false))
        .catch(() => setAddSelected(false));
    } else if (type === "colloborators") {
      const formData = {
        lg_id: lg_id,
        owner_lg_id: addSelected,
        owner_type: "Collab",
      };
      addOwner(formData)
        .then(() => setAddSelected(false))
        .catch(() => setAddSelected(false));
    } else {
      const formData = { lg_id: lg_id, blocked_lg_id: addSelected };
      blockUser(formData)
        .then(() => setAddSelected(false))
        .catch(() => setAddSelected(false));
    }
  };
  return (
    <>
      <Stack spacing={1}>
        {value.length > 0 && (
          <Stack sx={{ display: "flex", flexWrap: "wrap" }}>
            {value.map((item, idx) => (
              <Chip
                sx={{ m: 0.5, width: "fit-content" }}
                key={idx}
                color={
                  type === "colloborators"
                    ? "info"
                    : type === "owners"
                    ? "success"
                    : "error"
                }
                avatar={<Avatar src={findWho(item.userId).readURL} />}
                label={item.name}
                onClick={() =>
                  navigate(`/profile/${item.link.split("/")[4]}/about`)
                }
                onDelete={
                  item.collab_rights === "explicit" || self
                    ? () => {
                        setDelete(item);
                      }
                    : null
                }
                deleteIcon={
                  item.collab_rights === "explicit" || self ? (
                    <CloseIcon />
                  ) : null
                }
              />
            ))}
          </Stack>
        )}
        <Autocomplete
          clearOnBlur
          options={possibleValue}
          freeSolo={false}
          noOptionsText={t("display.text.text.noEligible")}
          isOptionEqualToValue={(option, value) => option === value}
          getOptionLabel={(option) => option.name}
          renderOption={(props, option) => (
            <Box component="li" {...props}>
              <Avatar sx={{ mr: 1 }} src={findWho(option.userId).readURL} />
              {option.name}
            </Box>
          )}
          renderInput={(params) => (
            <Box>
              <TextField
                sx={{ my: 1 }}
                {...params}
                label={t("display.text.text.add", {
                  param1: t(`display.text.text.${type}`),
                })}
              />
            </Box>
          )}
          onChange={(_, data) => {
            setAddSelected(data?.userId);
          }}
        />
        <LoadingButton
          onClick={onAdd}
          sx={{ width: "100%" }}
          variant="contained"
          disabled={!addSelected}
          loading={addOwnerLoading || addBlockLoading}
          color={
            type === "colloborators"
              ? "info"
              : type === "owners"
              ? "success"
              : "error"
          }
        >
          {t(`display.text.button.add${type}`)}
        </LoadingButton>
      </Stack>
      <DeleteEntryPromt
        open={Boolean(deleteUser)}
        close={() => {
          setDelete(false);
        }}
        title={t("display.text.text.remove")}
        message={
          type === "colloborators"
            ? t("display.text.text.confirmRemove", {
                param1: t(`display.text.text.${type}`, { count: 1 }),
              })
            : type === "owners"
            ? t("display.text.text.confirmRemove", {
                param1: t(`display.text.text.${type}`, { count: 1 }),
              })
            : t("display.text.text.confirmUnblock")
        }
        onCancel={() => setDelete(false)}
        onDelete={onRemove}
        type="error"
      />
    </>
  );
};
