import Card from "@mui/material/Card";
import Grid from "@mui/material/Grid";
import Box from "@mui/material/Box";
import Typography from "@mui/material/Typography";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import {
  findEventType,
  findWho,
  formatDate,
  generateRandomLifeEventSticker,
} from "../../utils/functions";
import { api, lifeEventReactions } from "../../utils/constants";
import Highlighter from "react-highlight-words";
import { RichTextViewer } from "../../UI/RichTextViewer";
import { ReactionViewer } from "../molecules/ReactionViewer";
import Button from "@mui/material/Button";
import ChatBubbleOutlineIcon from "@mui/icons-material/ChatBubbleOutline";
import Stack from "@mui/material/Stack";
import { ReactionAndCommentBtn } from "../molecules/ReactionAndCommentBtn";
import { MenuButton } from "../molecules/MenuButton";
import { DeleteEntryPromt } from "../atoms/DeleteEntryPromt";
import { t } from "i18next";
import { useDeleteEntryMutation } from "../../redux/slice/deleteEntry";
import { CustomDialog } from "../../UI/CustomDialog";
import { MediaGrid } from "./MediaGrid";
import { AddComment } from "../molecules/AddComment";
import { Img } from "../../UI/Img";
import { NewLifeEvent } from "../forms/NewLifeEvent";
import CustomAvatar from "../../UI/CustomAvathar";
import { InfoPopup } from "../atoms/InfoPopup";

export const LifeEventContainer = (props) => {
  const {
    comment_cnt,
    created_lg,
    created_lg_url,
    desc,
    dt,
    entry_created_by,
    file_type,
    id,
    my_reaction,
    reaction_cnt,
    readURL,
    title,
    type,
    uniq_reaction_list,
    updated_date,
    visibility,
    searchValue = "",
    user_connect_to_lg,
    lg_id,
    fstr,
  } = props;
  //-----------------------Declare Hooks Here-----------------------------//

  const { device } = useSelector((state) => state.config);
  const { userStatus } = useSelector((state) => state.config);
  const [sticker, setSticker] = useState(generateRandomLifeEventSticker(type));
  const [deletePromt, setDeletePromt] = useState(false);
  const [commentFocus, setCommentFocus] = useState(false);
  const [editPromt, setEditPromt] = useState(false);
  const [infoPromt, setInfoPromt] = useState(false);
  const [expandedView, setExpandedView] = useState(false);
  const [deleteEntry] = useDeleteEntryMutation();
  const isMobile = device === "xs" || device === "sm";
  const typ = "le";
  const isEditable =
    (user_connect_to_lg?.user_owner_type === "Self" ||
      user_connect_to_lg?.user_owner_type === "Owner") &&
    userStatus === "active"
      ? true
      : user_connect_to_lg?.user_owner_type === "Collab" &&
        entry_created_by === "Me" &&
        userStatus === "active"
      ? true
      : false;
  const src =
    readURL !== "NOFILE"
      ? readURL
      : sticker
      ? `${api.lifeEventSticker}/${sticker.sticker[sticker.count]}`
      : null;
  const who = findWho(lg_id);

  //-----------------------Declare function Here-----------------------------//
  const handleDelete = () => {
    deleteEntry({
      fstr: fstr,
      lg_id: lg_id,
      entry_id: id,
      entry_type: findEventType(typ).delete,
      file_type: file_type,
    });
  };

  const onExpand = (value) => {
    if (value === "comment") {
      setCommentFocus(true);
    } else {
      setCommentFocus(false);
    }
    setExpandedView(true);
  };

  return (
    <Card
      sx={{
        width: "100%",
        p: 0.5,
      }}
    >
      <Stack spacing={0.75}>
        <Grid
          container
          sx={{ height: { xs: "unset", sm: "unset", md: 160, lg: 160 } }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            md={4}
            lg={4}
            sx={{
              height: { xs: "256px", sm: "256px", md: "100%", lg: "100%" },
              width: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: "black.main",
              borderRadius: 1,
              overflow: "hidden",
            }}
          >
            {readURL && (
              <Img
                style={{
                  width: isMobile ? "" : "100%",
                  height: isMobile ? "256px" : "100%",
                  maxHeight: isMobile ? "350px" : "256px",
                  objectFit: "contain",
                }}
                key={src}
                loading="lazy"
                src={src}
                alt="lifeEvent"
                onClick={onExpand}
              />
            )}
          </Grid>
          <Grid
            sx={{
              pl: { xs: 0, md: 1 },
              height: { xs: "fit-content", sm: "100%" },
              position: "relative",
            }}
            item
            xs={12}
            sm={12}
            md={8}
            lg={8}
          >
            <Box
              sx={{
                flex: 1,
                display: "flex",
                flexDirection: "column",
                height: "100%",
              }}
            >
              <Box
                sx={{
                  display: "flex",
                  width: "100%",
                  alignItems: "center",
                }}
              >
                <Box sx={{ width: "91%" }}>
                  {(searchValue || title) && (
                    <Typography
                      sx={{
                        overflow: "hidden",
                        textOverflow: "ellipsis",
                        textWrap: "nowrap",
                        fontSize: "16px",
                        fontWeight: 600,
                        color: "text.heading",
                      }}
                      onClick={onExpand}
                    >
                      {!searchValue ? (
                        title
                      ) : (
                        <Highlighter
                          highlightClassName="YourHighlightClass"
                          searchWords={searchValue.split(" ")}
                          autoEscape={true}
                          textToHighlight={title}
                        />
                      )}
                    </Typography>
                  )}
                </Box>
                {isEditable && (
                  <Box sx={{ zIndex: 5, width: "10%" }}>
                    <MenuButton
                      entry_created_by={entry_created_by}
                      lg_id={lg_id}
                      entry_id={id}
                      entry_type={typ}
                      onDelete={setDeletePromt}
                      onEdit={setEditPromt}
                      onInfo={setInfoPromt}
                    />
                  </Box>
                )}
              </Box>
              {dt && (
                <Typography variant="caption">{formatDate(dt)}</Typography>
              )}
              <Box
                sx={{
                  display: "flex",
                  alignItems: "center",
                  height: "100%",
                }}
              >
                {(searchValue || desc) && (
                  <Typography
                    sx={{
                      fontSize: "16px",
                      fontWeight: 400,
                      color: "text.body",
                    }}
                    onClick={onExpand}
                    component={"div"}
                  >
                    {!searchValue ? (
                      <RichTextViewer
                        text={desc}
                        truncate={true}
                        truncateChar={150}
                      />
                    ) : (
                      <Highlighter
                        highlightClassName="YourHighlightClass"
                        searchWords={searchValue.split(" ")}
                        autoEscape={true}
                        textToHighlight={desc.blocks[0].text}
                      />
                    )}
                  </Typography>
                )}
              </Box>
              {(Boolean(Number(reaction_cnt)) ||
                Boolean(Number(comment_cnt))) && (
                <Stack
                  spacing={1}
                  direction={"row"}
                  sx={{ display: "flex", alignItems: "center", py: 1 }}
                >
                  {Boolean(Number(reaction_cnt)) && (
                    <Stack
                      spacing={1}
                      direction={"row"}
                      sx={{ display: "flex", alignItems: "center" }}
                    >
                      <ReactionViewer
                        reaction_cnt={reaction_cnt}
                        uniq_reaction_list={uniq_reaction_list}
                        entry_id={id}
                        entry_type={typ}
                        lg_id={lg_id}
                      />
                      {Boolean(Number(comment_cnt)) && (
                        <Box
                          sx={{
                            borderRight: "solid",
                            borderRightWidth: "thin",
                            borderRightColor: "grey.text",
                            height: "10px",
                          }}
                        />
                      )}
                    </Stack>
                  )}

                  {Boolean(Number(comment_cnt)) && (
                    <Button
                      sx={{
                        minHeight: 0,
                        minWidth: 0,
                        p: 0,
                        color: "grey.text",
                      }}
                      onClick={onExpand}
                    >
                      <Stack
                        direction={"row"}
                        alignItems={"center"}
                        spacing={0.25}
                      >
                        <Typography variant="caption" sx={{ fontSize: "14px" }}>
                          {comment_cnt}
                        </Typography>
                        <ChatBubbleOutlineIcon sx={{ fontSize: "14px" }} />
                      </Stack>
                    </Button>
                  )}
                </Stack>
              )}
            </Box>
          </Grid>
        </Grid>
        {userStatus === "active" && (
          <ReactionAndCommentBtn
            id={id}
            entry_type={typ}
            lg_id={lg_id}
            my_reaction={my_reaction}
            reaction_cnt={reaction_cnt}
            uniq_reaction_list={uniq_reaction_list}
            reaction={lifeEventReactions}
            onClick={onExpand}
          />
        )}
      </Stack>
      <DeleteEntryPromt
        onCancel={setDeletePromt}
        onDelete={handleDelete}
        open={deletePromt}
        close={setDeletePromt}
        message={t("display.text.text.deleteConfirmation", {
          param1: t(`display.text.button.${findEventType(typ).title}`, {
            count: 1,
          }),
        })}
        type="error"
      />
      {expandedView && (
        <CustomDialog
          open={Boolean(expandedView)}
          close={setExpandedView}
          action={
            userStatus === "active" && (
              <AddComment
                lg_id={lg_id}
                entry_id={id}
                entry_type={typ}
                focusOnInput={commentFocus}
              />
            )
          }
          title={
            <Stack
              sx={{
                display: "flex",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
              }}
              spacing={1}
              direction={"row"}
            >
              <CustomAvatar src={who?.readURL} />
              <Box>
                <Typography
                  variant="h6"
                  component={"div"}
                  align="center"
                  sx={{ fontSize: "22px", fontWeight: 500, color: "#333333" }}
                >
                  {who?.name}
                </Typography>
                <Typography
                  variant="caption"
                  component={"div"}
                  sx={{ fontSize: "14px" }}
                >
                  {t(`display.text.button.${findEventType(typ).title}`, {
                    count: 1,
                  })}
                </Typography>
              </Box>
            </Stack>
          }
        >
          {expandedView && <MediaGrid {...props} typ={typ} readURL={src} />}
        </CustomDialog>
      )}
      {editPromt && (
        <NewLifeEvent
          open={editPromt}
          close={setEditPromt}
          mode="edit"
          {...props}
          lg_id={lg_id}
        />
      )}
      {infoPromt && (
        <InfoPopup
          open={infoPromt}
          close={() => setInfoPromt(false)}
          updatedOn={formatDate(props?.updated_date)}
          createdBy={findWho(props?.created_lg)?.name}
        />
      )}
    </Card>
  );
};
