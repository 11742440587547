import Card from "@mui/material/Card";
import React from "react";
import { GreetingCard } from "../../UI/greetingCard/GreetingCard";
import { findExactOwner, findWho } from "../../utils/functions";
import { WishReaction } from "../atoms/WishReaction";
import { wishReactions } from "../../utils/constants";
export const WishContainer = ({
  created_date = "",
  creator_link = "",
  creator_name = "",
  entry_created_by = "",
  mention_lg_id = "",
  owner_reaction = "",
  sticker_name = "",
  desc = "",
  id = "",
  style,
  lg_id,
  homeFeed = false,
}) => {
  const showButton = !homeFeed && findExactOwner(lg_id);
  return (
    <>
      <Card sx={{ width: "100%", py: 1 }}>
        <GreetingCard
          baseURL="https://stor1.famscape.com/openstore/wishdesigns"
          sticker_name={sticker_name}
          creator_name={creator_name}
          wish_style={style}
          wish_desc={desc}
          recieverName={findWho(lg_id)?.name}
          created_date={created_date}
        />
        {showButton && (
          <WishReaction
            sticker_name={sticker_name}
            entry_created_by={entry_created_by}
            creator_name={creator_name}
            desc={desc}
            id={id}
            owner_reaction={owner_reaction}
            lg_id={lg_id}
            reaction={wishReactions}
            my_reaction={owner_reaction}
          />
        )}
      </Card>
    </>
  );
};
