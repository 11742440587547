import Box from "@mui/material/Box";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import Divider from "@mui/material/Divider";
import React from "react";
import { t } from "i18next";
import GoogleAuthentication from "../atoms/GoogleAuthentication";
import LoginForm from "../forms/Login";

const LogIn = () => {
  return (
    <Stack
      sx={{
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        maxWidth: "520px",
        width: "100%",
      }}
      spacing={3}
    >
      <Box>
        <Typography variant="h5">{t("display.text.text.login")}</Typography>
        {/* <h6>{t("display.text.text.signupHeader")}</h6> */}
      </Box>
      <Box>
        <GoogleAuthentication />
      </Box>
      <Box sx={{ display: "flex", width: "100%" }}>
        <Divider
          sx={{
            width: "100%",
            "&::before": { mt: "4px" },
            "&::after": { mt: "4px" },
          }}
        >
          {t("display.text.text.or")}
        </Divider>
      </Box>
      <LoginForm />
    </Stack>
  );
};
export default LogIn;
