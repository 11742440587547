import React, { useRef } from "react";
import { Swiper, SwiperSlide } from "swiper/react";
import { Navigation } from "swiper/modules";
import { PossibleRelatioCard } from "../molecules/PossibleRelatioCard";
import NavigateNextIcon from "@mui/icons-material/NavigateNext";
import NavigateBeforeIcon from "@mui/icons-material/NavigateBefore";
import "swiper/css";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import Stack from "@mui/material/Stack";
import Typography from "@mui/material/Typography";
import { t } from "i18next";
import { useContainerDimensions } from "../../utils/hooks";
import { useDispatch, useSelector } from "react-redux";
import homeFeed from "../../redux/slice/homeFeed";
import { getUserSummaryApi } from "../../api/getUserSummaryApi";

export const PossibleRelation = ({ PossibleRelationArr }) => {
  //-----------------------Declare Hooks Here-----------------------------//

  const ref = useRef(null);
  const { width } = useContainerDimensions(ref);
  const { device } = useSelector((state) => state.config);
  const dispatch = useDispatch();
  const mobile = device === "xs" || device === "sm";
  const successCallBack = (relation_of_lg, userId, type = "relation") => {
    if (type === "relation") {
      dispatch(
        homeFeed.util.updateQueryData("getHomeFeed", "homeFeed", (draft) => {
          draft.potentialRelativesList = draft.potentialRelativesList.filter(
            (item) =>
              item.userId !== userId || item.relation_of_lg !== relation_of_lg
          );
        })
      );
      getUserSummaryApi();
    } else {
      dispatch(
        homeFeed.util.updateQueryData("getHomeFeed", "homeFeed", (draft) => {
          draft.potentialRelativesList = draft.potentialRelativesList.filter(
            (item) => item.userId !== userId
          );
        })
      );
    }
  };
  const rejectCallback = (relation_of_lg, userId, type = "relation") => {
    if (type === "relation") {
      dispatch(
        homeFeed.util.updateQueryData("getHomeFeed", "homeFeed", (draft) => {
          draft.potentialRelativesList = draft.potentialRelativesList.filter(
            (item) =>
              item.userId !== userId || item.relation_of_lg !== relation_of_lg
          );
        })
      );
    }
  };
  return (
    <Card sx={{ p: 1 }} ref={ref}>
      <Stack spacing={1}>
        <Typography sx={{ fontSize: "16px", fontWeight: "600" }}>
          {t("display.text.text.potentialRelative")}{" "}
        </Typography>
        <Box sx={{ position: "relative" }}>
          {!mobile && (
            <Box className="swiper-button image-swiper-button-next">
              <NavigateNextIcon />
            </Box>
          )}
          {!mobile && (
            <Box className="swiper-button image-swiper-button-prev">
              <NavigateBeforeIcon />
            </Box>
          )}
          <Swiper
            navigation={{
              nextEl: ".image-swiper-button-next",
              prevEl: ".image-swiper-button-prev",
              disabledClass: "swiper-button-disabled",
            }}
            modules={[Navigation]}
            className="mySwiper"
            slidesPerView={width / 175}
            spaceBetween={30}
            pagination={{
              clickable: true,
            }}
          >
            {PossibleRelationArr.map((item) => (
              <SwiperSlide>
                <PossibleRelatioCard
                  {...item}
                  successCallBack={successCallBack}
                  rejectCallBack={rejectCallback}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        </Box>
      </Stack>
    </Card>
  );
};
