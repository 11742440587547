import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";
import { referrerApi } from "../../api/referrerApi";
import Box from "@mui/material/Box";

const Protected = ({ auth, children }) => {
  //-----------------------Declare Hooks Here-----------------------------//
  const urlParams = new URLSearchParams(window.location.search);
  const myParam = urlParams.get("token");
  const lgUrl = urlParams.get("ref");
  const isLoggedIn = useSelector((state) => state.authentication.isLoggedIn);
  const userId = useSelector((state) => state.authentication.usrId);
  const userToken = useSelector((state) => state.authentication.usrToken);

  //-----------------------Declare Functions Here-----------------------------//
  const handleReferrerApi = (param) => {
    referrerApi(param, userId, userToken);
  };

  if (auth) {
    if (isLoggedIn === true)
      return (
        <Box
          sx={{
            backgroundColor: "background.main",
            height: "100vh",
            overflow: "auto",
          }}
        >
          {children}
        </Box>
      );
    else if (isLoggedIn === false) return <Navigate to={"/login"} replace />;
  } else if (!auth) {
    if (isLoggedIn === false) return <Box>{children}</Box>;
    else if (isLoggedIn === true) {
      if (myParam !== null) {
        handleReferrerApi(myParam);
        if (lgUrl) return <Navigate to={`/profile/${lgUrl}/about`} replace />;
      }
      return <Navigate to={"/"} replace />;
    }
  }
};

export default Protected;
